import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Alert, AlertTitle, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from './add-prescription.module.scss';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ProgressBar from '../../support-module/progress-bar/progress-bar';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { v4 as uuidv4 } from 'uuid';
import { PrescriptionApi } from '../../../constants/ApiConstants';
import { useLocation } from "react-router-dom";
import { HTTPMethod, callApi as AddPrescriptionFile  } from "../../../services/HttpService/HttpService";
import { successToast } from '../../../components/ToastMessage/ToastMessage';
import { DocumentType } from '../../../common/enums';

export const DocumentProgressBar = () => {
  const [progress, setProgress] = useState(0);
  let progressTimer;
  useEffect(() => {
    if (progress < 100) {
      progressTimer = setTimeout(() => {
        setProgress(progress + 10);
      }, 200);
    }
    return () => {
      clearTimeout(progressTimer);
    };
  }, [progress]);
  return (
    <div className={styles.prescriptionFlex}>
      <div className={styles.progressWidth}>
        {progress == 100 ? <ProgressBar value={100} label={`${100}%`} />
          : <ProgressBar value={progress} label={`${progress}%`} />}
      </div>
      <div className={styles.progressBarPos}>
        {progress == 100 ? (<CheckIcon data-testid="completed" sx={{ marginLeft: '4px', fontSize: '1.2rem', color: 'var(--color-darkgray-500)' }} />) : (<span>{progress}%</span>)}
      </div>
    </div>
  );
};
export const AddDocument = ({ selectedFiles, setSelectedFiles, filesRef, fileObj, setFileObj, uploadFilePermissions, maxAttachments = 10 , prescriptionReportOrderid = ''}) => {
  const [fileSizeError, setFileSizeError] = useState('');
  const strRegex = "^[a-zA-Z0-9_ \-]+\.[a-zA-Z0-9]{1,10}$";
  const validFilesRegex = new RegExp(strRegex);
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 900;
  const handleDelete = (indexToDelete) => {
    const updatedFileData = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );
    const fileLength = selectedFiles.length;
    const updatedFileObj = {}
    const { [selectedFiles[indexToDelete].name + indexToDelete]: _, ...restObj } = fileObj;
    let prevFileObj = { ...restObj }
    for (let i = indexToDelete; i < fileLength - 1; i++) {
      const { [selectedFiles[i + 1].name + (i + 1)]: _, ...tempFileObj } = prevFileObj;
      prevFileObj = tempFileObj;
      updatedFileObj[updatedFileData[i].name + i] = { ...fileObj[selectedFiles[i + 1].name + (i + 1)] } ;
    }
  setFileObj({ ...prevFileObj, ...updatedFileObj})
    filesRef.current.value = '';
    setSelectedFiles(updatedFileData);
  };
  const handleTypeChange = (e, name) => {
    if (e) {
      setFileObj({
        ...fileObj,
        [name]: {
          ...fileObj[name],
          docType: e.target.value,
          isFileTypeInvalid:false
        }
      });
    }
  };
  const handleExpirationDate = (val, name) => {
    setFileObj({
      ...fileObj, [name]: {
        ...fileObj[name],
        date: val,
        isDateNotSelected:false
      }
    });
  };
  const checkFileLength = (fileArray) =>{
    let isFileInvalid = false;
    fileArray.forEach(item => {
      const maxLength = 201 + item?.name?.split('.').pop().length
      if (item?.name?.length > maxLength){
        isFileInvalid = true;
      }
     })
     return isFileInvalid;
    }
  const handleSetFiles = (file) => {
    if(file.length){
      const files = [...selectedFiles,...file]
      const prevFileLength = selectedFiles.length;
      const newFileObj = {}
      file.forEach((item, index) => {
        newFileObj[item.name + (index + prevFileLength)] = {
          guidName: uuidv4() + "." + item.name.split(".")[1], docType: prescriptionReportOrderid ? 1 : null
        };
      })
      setSelectedFiles(files);
      setFileObj({...fileObj,...newFileObj})
      filesRef.current.value = '';
    }
  };
  const checkFileNameValid = (fileArray) =>{
    let isFileInvalid = false;
    fileArray.forEach(item=>{
      validFilesRegex.lastIndex = 0;
      if (!validFilesRegex.test(item.name)){
        isFileInvalid = true;
      }
    })
    return isFileInvalid;
  }
  const checkFileType = (fileArray) =>{
    let isFileInvalid = false;
    const allowedExt = ["pdf", "jpg", "jpeg", "png"]
    fileArray.forEach(item=>{
      const fileExt = item.name?.split(".").pop().toLowerCase();
      if (!allowedExt.includes(fileExt)){
        isFileInvalid = true;
      }
    })
    return isFileInvalid;
  }
  const maxSize = uploadFilePermissions?.file_upload_maximum_size?.slice(0, -2) ?? 4
  const maxFileSizeInBytes = +maxSize * 1024 * 1024;
  const handleFileChange = (event) => {
    const newSelectedFiles = Array.from(event.target.files);
    const totalSelectedFiles = selectedFiles.length + newSelectedFiles.length;
    if (newSelectedFiles.length > 0 && totalSelectedFiles <= maxAttachments) {
      const remainingFile = Math.max(0, maxAttachments - selectedFiles.length);
      const additionalFiles = newSelectedFiles.slice(0, remainingFile);
      checkValidFiles(additionalFiles);
    }
    else if (newSelectedFiles.length > 0 && (newSelectedFiles.length + selectedFiles?.length) > 10) {
      setFileSizeError(`${t('Prescriptions.fileCountError')} ${uploadFilePermissions?.file_upload_maximum_count}.`);
    }
  };

  const checkValidFiles = (additionalFiles) =>{
    const validFiles = additionalFiles.filter((file) => file.size <= maxFileSizeInBytes);
      if (validFiles.length < additionalFiles.length) {
        setFileSizeError(`${t('Prescriptions.theMaxFileSizeIs')} ${uploadFilePermissions?.file_upload_maximum_size}`);
      } else if (checkFileLength(validFiles)) {
        setFileSizeError(t('Prescriptions.fileNameMaxLength'));
      }
      else if (checkFileNameValid(validFiles)) {
        setFileSizeError(t('Prescriptions.invalidFileName'));
      } else if (checkFileType(validFiles)) {
        setFileSizeError(t('Prescriptions.invalidFileExtension'));
      } else if (validFiles.length > maxAttachments) {
        setFileSizeError(`${t('Prescriptions.fileCountError')} ${uploadFilePermissions?.file_upload_maximum_count}.`);
      }
      else {
        setFileSizeError('');
          handleSetFiles(validFiles);
      }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={styles.DialogData} onDragOver={(event) => {
          if (isMobile) {
            event.preventDefault();
            return;
          }
          event.preventDefault();
        }}
          onDrop={(event) => {
            if (isMobile) {
              event.preventDefault();
              return;
            }
            event.preventDefault();
            const newSelectedFiles = Array.from(event.dataTransfer.files);
            const totalSelectedFiles = selectedFiles.length + newSelectedFiles.length;
            if (newSelectedFiles.length > 0 && totalSelectedFiles <= maxAttachments) {
              const remainingFile = Math.max(0, (maxAttachments - selectedFiles.length));
              const additionalFiles = newSelectedFiles.slice(0, remainingFile);
              if (newSelectedFiles.length > 0 && (newSelectedFiles.length + selectedFiles?.length) > 10) {
                setFileSizeError(`${t('Prescriptions.fileCountError')} ${uploadFilePermissions?.file_upload_maximum_count}.`);
              } 
              else if (selectedFiles?.findIndex(item => item.name == additionalFiles?.name) == -1) {
                checkValidFiles(additionalFiles);
              }
            }  
          }}>
          <div className={styles.cloudContainer}>
            <CloudUploadIcon sx={{ fontSize: "60px" }} />
            {!isMobile && <div className={styles.ImageUploadDescription}>
              <p className={styles.ImageUploadDescription}>
                {t('Prescriptions.dragAndDropOrClickToSelectFiles')}
              </p>
            </div>}
            <label
              htmlFor="fileInput"
              className={`${styles.DialogButton} ${styles.DisabledDialogButton}`}
            >
              {t('Prescriptions.selectFile')}
            </label>
            <div className={styles.errorText}>{fileSizeError}</div>
            <input
              type="file"
              id="fileInput"
              ref={filesRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              multiple
              accept=".pdf, .png, .jpg, .jpeg"
            />
          </div>
          <div className={styles.instructionsList}>
            <ul>
              <li>{t('Prescriptions.theMaxFileSizeIs')} {uploadFilePermissions?.file_upload_maximum_size}.</li>
              <li>{t('Prescriptions.allowedFileTypes')}</li>
            </ul>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <>
          {selectedFiles?.length > 0 && <div className={styles.gridClassMain} style={{ marginTop: 14 }}>
            {selectedFiles?.map((file, index) => ((<div tabIndex="0" key={fileObj[file.name + index].guidName} id={file.name + index}>
              <div className={styles.gridClass}>
                <div className={styles.iconDiv}>
                  <IconButton sx={{ padding: '0px' }}
                    data-testid={("CloseIcon-" + file?.name)}
                    className={styles.customIconContainer}
                    onClick={() => handleDelete(index)}>
                    <CloseIcon style={{ marginTop: "8px" }} />
                  </IconButton></div>
                <div className={styles.content}>
                  {file.name}
                </div>
                <div>
                  <p>
                   <span className={styles.sizeColor}>
                   {t('Prescriptions.size')}: {' '}
                    </span>  
                  <span className={styles.fileSizeColor}>
                  {((file.size) / (1024 * 1024)).toFixed(2)} MB
                    </span></p>
                </div>
                <div>
                { uploadFilePermissions?.o2_prescriptions_module_enabled && !prescriptionReportOrderid && <FormControl fullWidth size="small" sx={{
                    borderRadius: "8px", ".MuiInputBase-root": {
                      height: "30px"
                    }
                  }}>
                    <InputLabel id="fileLabel" size="small" error={!!fileObj[file.name + index]?.isFileTypeInvalid} sx={{
                      top: -6, "&.MuiInputLabel-shrink": {
                        top: "0px !important"
                      }
                    }}>{t("Prescriptions.fileTypeRequired")}</InputLabel>
                    <Select data-testid={("Select-" + file?.name)} label="File Type(required)" error={!!fileObj[file.name + index]?.isFileTypeInvalid} labelId="fileLabel" id="fileType"  value={fileObj[file.name + index]?.docType ?? (uploadFilePermissions?.o2_prescriptions_module_enabled ? "" : 2)} onChange={(e) => { handleTypeChange(e, file.name + index); }} IconComponent={KeyboardArrowDownRoundedIcon}>
                      <MenuItem value={1}>{t("Prescriptions.oxygenPrescription")}</MenuItem>
                      <MenuItem value={2}>{t("Prescriptions.otherFile")}</MenuItem>
                    </Select>
                  </FormControl>}
                </div>
                <div className={styles.fieldFlex} >
                  {fileObj[file.name + index]?.docType == 1 && <FormControl fullWidth size="small" sx={{
                    borderRadius: "8px", ".MuiInputBase-root": {
                      height: "30px"
                    }
                  }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DatePicker
                        label="Expiration (required)"
                        value={fileObj[file.name + index]?.date ?? null}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                            error: !!fileObj[file.name + index]?.isDateNotSelected,
                          },
                        }}
                        onChange={(val) => { handleExpirationDate(val, file.name + index);}}
                        sx={{
                          ".MuiFormLabel-root": {
                            top: -11,
                            "&.MuiInputLabel-shrink": {
                              top: "0px !important",
                            },
                          },
                        }}>
                      </DatePicker>
                    </LocalizationProvider>
                    {fileObj[file.name + index]?.isDateNotSelected &&  <FormLabel error>
                      {t("Prescriptions.validDate")}
                    </FormLabel>}
                  </FormControl>}
                </div>
                  <DocumentProgressBar key={fileObj[file.name + index].guidName} />
              </div>
            </div>
            )))}
          </div>}
        </>
      </Grid>
    </Grid>);
};
export const AlertPrescription =({t}) =>{
  return(
  <Alert severity="warning" icon={false} className={styles.alertContainer}>
  <AlertTitle style={{ fontWeight: "700", fontSize: "var(--secondary-heading)" }} className={styles.alertHeading}>{t("Prescriptions.prescriptionRequirements")}
  </AlertTitle>
  <div style={{ fontWeight: "bold", fontSize: "var(--common-heading)"}}>
    {t("Prescriptions.uploadedFileContent")}
  </div>
  <div className={styles.documentContainer}>
    <Grid>
      <ul>
        <li>
          {t("Prescriptions.beneficiaryName")},
        </li>
        <li>
          {t("Prescriptions.modalitiesAndDevicesDescription")},
        </li>
        <li>
          {t("Prescriptions.orderingPhysicianOrNppSignature")},
        </li>
        <li>
          {t("Prescriptions.orderDateAndStartDate")},
        </li>
      </ul>
    </Grid>
    <Grid >
      <ul>
        <li>
          {t("Prescriptions.prescribingPractitionerNPI")},
        </li>
        <li>
          {t("Prescriptions.o2FlowRate")},
        </li>
        <li>
          {t("Prescriptions.estimatedFrequencyAndDuration")}
        </li>
        <li>
          {t("Prescriptions.durationOfNeed")}
        </li>
      </ul>
    </Grid>
  </div>
</Alert>
  )
}

const AddPrescription = ({ isOpen, onClose, fileData, setFileData, prescriptionsModuleEnabled, addPrescriptionSettings, prescriptionReportOrderid = '', fetchPrescriptionReportData = () => {}}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const location = useLocation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileObj, setFileObj] = useState({});
  const [isHiddenValid, setIsHiddenValid] = useState(false);
  const [addPrescriptionEnable, setAddPrescriptionEnable] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasInvalidDate, setHasInvalidDate] = useState(false);

  const handleUploadForPrescription = async () => {
    setUploadInProgress(true);
    const totalFiles = selectedFiles.length;
    const orderId = location.pathname.split("/").pop();
    const component = location.pathname.includes('order') ? 2 : 1;
    const updatedFileObj = { ...fileObj };
    let hasInvalidDate = false;
    Object.keys(updatedFileObj).forEach(file => {
      if (
        updatedFileObj[file]?.docType === 1 &&
        (!updatedFileObj[file]?.date ||
          updatedFileObj[file]?.date.toLocaleString() === "Invalid Date" ||
          updatedFileObj[file]?.date?.$y < 1900 ||
          updatedFileObj[file]?.date?.$y >= 2100)
      ) {
        updatedFileObj[file] = {
          ...updatedFileObj[file],
          isDateNotSelected: true,
        };
        hasInvalidDate = true;
      }
    });
    setHasInvalidDate(hasInvalidDate);
    setFileObj(updatedFileObj);
    if (hasInvalidDate) {
      return;
    }
    setFileObj(updatedFileObj);
    if (hasInvalidDate) {
      return;
    }
    const requestBody = {
      file_name_list: Object.values(fileObj).filter(item => item.guidName).map(item => item.guidName),
      component_id: prescriptionReportOrderid ? prescriptionReportOrderid : orderId,
      component: prescriptionReportOrderid ? 2 : component
    };
    const response = await AddPrescriptionFile(
      HTTPMethod.Post,
      PrescriptionApi.generatePrescriptionListing,
      requestBody
    );
    if (response && response.status_code == 200 && response.data && response.data.signed_file_url_list) {
      selectedFiles.forEach(async (element, index) => {
        await fetch(response.data.signed_file_url_list[fileObj[element.name + index].guidName], {
          body: element,
          method: HTTPMethod.Put,
        })
        const currentProgress = ((index + 1) / totalFiles) * 100;
        setProgress(currentProgress);
        if (index === selectedFiles.length - 1) {
          handleAddFilePrescription();
        }
      });
    }
  }
  const handleAddFilePrescription = async () => {
    const orderId = location.pathname.split("/").pop();
    const component = location.pathname.includes('order') ? 2 : 1;
    const formatDate = (date) => {
      const pad = (num) => (num < 10 ? `0${num}` : num);
      const formattedDate = new Date(date);
      const year = formattedDate.getFullYear();
      const month = pad(formattedDate.getMonth() + 1);
      const day = pad(formattedDate.getDate());
      const hours = pad(formattedDate.getHours());
      const minutes = pad(formattedDate.getMinutes());
      const seconds = pad(formattedDate.getSeconds());
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    };
  
    const requestBody = {
      add_file_info: selectedFiles.map((item,index) => {
        return {
          file_name: item.name,
          component_id: prescriptionReportOrderid ? prescriptionReportOrderid : orderId,
          component: prescriptionReportOrderid ? 2 : component,
          stored_file_name: fileObj[item.name + index].guidName,
          is_o2_prescription: fileObj[item.name + index].docType === DocumentType.oxygenPrescription,
          expiration_date: fileObj[item.name + index].date ? formatDate(fileObj[item.name + index].date) : null
        }
      })
    };
  
    const response = await AddPrescriptionFile(
      HTTPMethod.Post,
      PrescriptionApi.addPrescriptionFile,
      requestBody
    );
  
    if (response && response.status_code == 200 && response.data) {
      onClose();
      setUploadInProgress(false);
      setProgress(0);
      setSelectedFiles([]);
      setFileObj({});
      setAddPrescriptionEnable(false);
      setIsHiddenValid(false);
      prescriptionReportOrderid && fetchPrescriptionReportData(true);
      successToast(t("Prescriptions.documentAddedSuccessfully"));
    }
  }

  useEffect(() => {
    if(!isOpen){
     setSelectedFiles([]);
     setFileObj({});
     setIsHiddenValid(false);
    }
   }, [isOpen])

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const allFilesHaveDocType = selectedFiles.every((item,index) => !!fileObj[item.name + index]?.docType);
      setAddPrescriptionEnable(allFilesHaveDocType || !prescriptionsModuleEnabled);
    } else {
      setAddPrescriptionEnable(false);
    }
  }, [fileObj, selectedFiles]);

  const handleViewRequirements = () => {
    setIsHiddenValid(!isHiddenValid);
  }

  return (
    <div data-testid="AddAttachment">
      {isOpen && (
        <Dialog
          open={isOpen}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "65%",
                maxWidth: "65%",
                '@media (max-width:600px)': {
                  width: "80%",
                  maxWidth: "80%",
                },
              },
            },
          }}>
        <DialogTitle className={styles.title}>
            <div className={styles.dialogContainer}>
              <div className={styles.linkContainer}>
                <div className={styles.titleName}>
                  {t("Prescriptions.newDocument")}
                </div>
                {prescriptionsModuleEnabled && <div className={styles.linkMargin}>
                  <Link onClick={handleViewRequirements}
                    className={styles.viewAndHide}
                    sx={{ fontSize: '16px' }}>
                    {isHiddenValid ? 'Hide Rx requirements' : 'View Rx requirements'}
                  </Link>
                </div>}
              </div>
              <div>
                <IconButton sx={{color: 'var(--color-black)'}}
                  onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent className={styles.DialogContent}>
            {isHiddenValid &&
             <AlertPrescription t={t} />
            }
            <AddDocument
              uploadFilePermissions={addPrescriptionSettings}
              selectedFiles={selectedFiles}
              filesRef={fileInputRef}
              setSelectedFiles={setSelectedFiles}
              fileObj={fileObj}
              setFileObj={setFileObj}
              maxAttachments={addPrescriptionSettings?.file_upload_maximum_count}
              prescriptionReportOrderid={prescriptionReportOrderid}
            />
          </DialogContent>
          <div className={styles.progressBar}>{uploadInProgress  && !hasInvalidDate && <ProgressBar value={progress} label={`${progress}%`} />}</div>
          <DialogActions className={styles.actionButton}>
            <Button variant="contained" className='secondaryButton marginBottomEight' onClick={onClose}>
            {t("Prescriptions.cancel")}
            </Button>
            <Button
              variant="contained"
              data-testid="uploadFile"
              className='primaryButton marginBottomEight' 
              onClick={handleUploadForPrescription}
              disabled={!addPrescriptionEnable || (uploadInProgress && !hasInvalidDate )}>
              {t("Prescriptions.upload")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AddPrescription;
