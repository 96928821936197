import React, { useEffect, useState } from 'react';
import loader from '../../assets/loader.gif';
import NoData from '../noData/noData';
import { Card, CardContent, Grid, Dialog, Tooltip, Divider } from "@mui/material";
import { PreAuthComponentApi } from '../../constants/ApiConstants';
import { HTTPMethod, callApi as preAuthRequest } from '../../services/HttpService/HttpService';
import { useLocation} from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AirvoAuthorizationForm from '../PreAuthForms/PreAuthForms/AirvoAuthorizationForm';
import BariatricBedAuthorizationForm from '../PreAuthForms/PreAuthForms/BariatricBedAuthorizationForm';
import BrodaAuthorizationForm from '../PreAuthForms/PreAuthForms/BrodaAuthorizationForm';
import ElectricLiftAuthorizationForm from '../PreAuthForms/PreAuthForms/ElectricLiftAuthorizationForm';
import Group2AuthorizationForm from '../PreAuthForms/PreAuthForms/Group2AuthorizationForm';
import LiquidO2AuthorizationForm from '../PreAuthForms/PreAuthForms/LiquidO2AuthorizationForm';
import PortableO2 from '../PreAuthForms/PreAuthForms/PortableO2';
import RespiratoryTherapyForm from '../PreAuthForms/PreAuthForms/RespiratoryTherapyForm';
import TrilogyAuthorizationForm from '../PreAuthForms/PreAuthForms/TrilogyAuthorizationForm';
import WoundVacAuthorizationForm from '../PreAuthForms/PreAuthForms/WoundVacAuthorizationForm';
import styles from  "./PreAuthorizationTab.module.scss";
import { useTranslation } from 'react-i18next';
import { LineOfBusinessEnum } from '../../constants/BusinessConstants';
import ElectricLiftSystem from '../GentivaPreAuthForms/PrefilledForms/ElectricLiftSystem';
import BariatricSupport from '../GentivaPreAuthForms/PrefilledForms/BariatricSupport';
import NegativePressureWoundTherapy from '../GentivaPreAuthForms/PrefilledForms/NegativePressureWoundTherepy';
import SupportPreAuth from '../GentivaPreAuthForms/PrefilledForms/SupportPreAuth';
import HTRTiltAndBroda from '../GentivaPreAuthForms/PrefilledForms/HtrTiltAndBroda';
import Ventilator from '../GentivaPreAuthForms/PrefilledForms/Ventilator';
import HighFlowNasalCannula from '../GentivaPreAuthForms/PrefilledForms/HighFlowNasalCannula';
import BariatricMattress from '../GentivaPreAuthForms/PrefilledForms/BariatricMattress';
import LiquidOxygenSystem from '../GentivaPreAuthForms/PrefilledForms/liquidOxygenSystem';
import PortableOxygenConcentrator from '../GentivaPreAuthForms/PrefilledForms/PortableOxygenConcentrator';

const PreAuthorization = ({patientId}) => {
  const {t} = useTranslation();
  const [preAuthData, setPreAuthData] = useState([]);
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formName, setFormName] = useState('PortableO2');
  const [preAuthFormData, setPreAuthFormData] = useState(null);
  const showMessage = location.pathname.includes('order') ? 
  t('PreAuthForm.noPreAuthForm') : t('PreAuthForm.noPatientPreAuth').replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase()) ;
  
  const handleListItemClick = (formName, patientPreauthFormId) => {
    setFormName(formName);
    fetchPreAuthFormData(patientPreauthFormId);
  };
  
  const handleClose = () => {
    setModalOpen(false);
  };
  const formsPreAuth = {
    Airvo: <AirvoAuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    BariatricBed: <BariatricBedAuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    Broda: <BrodaAuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    ElectricLift: <ElectricLiftAuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    Group2: <Group2AuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    LiquidO2: <LiquidO2AuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    PortableO2: <PortableO2 handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    RespiratoryTherapy: <RespiratoryTherapyForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    Trilogy: <TrilogyAuthorizationForm handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    WoundVac: <WoundVacAuthorizationForm handleClose={ handleClose } preAuthFormData={preAuthFormData} />,
    ElectricLiftSystem:<ElectricLiftSystem handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    BariatricSupport:<BariatricSupport handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    NegativePressureWoundTherapy:<NegativePressureWoundTherapy handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    Support: <SupportPreAuth handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    HTRTiltAndBroda: <HTRTiltAndBroda handleClose={handleClose} preAuthFormData={preAuthFormData} />,
    Ventilator: <Ventilator handleClose={handleClose} preAuthFormData={preAuthFormData} />,
    BariatricMattress:<BariatricMattress handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    HighFlowNasalCannula:<HighFlowNasalCannula handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    LiquidOxygenSystem:<LiquidOxygenSystem handleClose={handleClose} preAuthFormData={preAuthFormData}/>,
    PortableOxygenConcentrator:<PortableOxygenConcentrator handleClose={handleClose} preAuthFormData={preAuthFormData}/>
  };
  
  const fetchPreAuthData = async () => {
    const requestBody = location.pathname.split('/').pop();
    const apiEndpoint = location.pathname.includes('order') ? 
      PreAuthComponentApi.patientsAuthDetails + "/" + (patientId ?? requestBody) + "/preauthorization?order_id=" + requestBody : PreAuthComponentApi.patientsAuthDetails + "/" + requestBody + "/preauthorization";
    setLoading(true);
    const response = await preAuthRequest(HTTPMethod.Get, apiEndpoint);
    if (response && response.status_code == 200 && response.data) {
      setPreAuthData(response.data.pre_auth_list);
    }
    setLoading(false);
  };

  const fetchPreAuthFormData = async (preAuthFormId) => {
    const apiEndpoint = PreAuthComponentApi.preAuthList + "/preauthorization/form/" + preAuthFormId;
    setLoading(true);
    const response = await preAuthRequest(HTTPMethod.Get, apiEndpoint);
    if (response && response.status_code === 200 && response?.data?.json_data?.form_data_json) {
      const formDataJsonArray = JSON.parse(response.data.json_data.form_data_json);
      if (formDataJsonArray && formDataJsonArray.length > 0) {
        setModalOpen(true);
        setPreAuthFormData(formDataJsonArray);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPreAuthData();
  }, []);

  return (
    <div className={styles.PreAuthorization} data-testid="OrderApprovalTabPreAuth">
      <Card className={styles.preAuthCard}>
        <div className={styles.preAuthMain}>
          <div className={`${styles.PatientsAuth} ${styles.preAuthBorder}`}>
            {preAuthData && preAuthData.length > 0 ? (
              preAuthData.map((PreAuthItem, index) => (
                <Card key={PreAuthItem.patient_preauth_form_id} variant="outlined" className={styles.preAuthWithoutBorder}>
                  <CardContent>
                    <Grid container >
                      <Grid item xs={12} sm={10} md={11} >
                        <div className={styles.preAuthSpan}>
                          <p className={styles.preAuthWithNoMargin}>
                            {PreAuthItem.form_display_name}
                          </p>         
                        </div>                 
                      </Grid>
                      <Grid item xs={12} sm={2} md={1} className={styles.buttonParentDiv}>
                        <Tooltip
                          title={
                            <span className='fontSizeThirteenPx'>{t('PreAuthForm.showForm')}</span>
                          }
                        >
                          <div key={PreAuthItem.patient_preauth_form_id} data-testid='list-item-click' onClick={() => handleListItemClick(PreAuthItem.form_name, PreAuthItem.patient_preauth_form_id)} 
                            className={styles.preAuthListDiv}><AssignmentIcon className={styles.assignmentIcon} /></div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={10} md={11}>
                      <div>
                        <span className={styles.content}>{t('PreAuthForm.createdBy')}: </span>
                        <span className={styles.scopedClassPreAuth}>{PreAuthItem.creator}</span>
                      </div>
                      <div>
                        <span className={styles.content}>{t('PreAuthForm.createdOn')}: </span>
                        <span className={styles.scopedClassPreAuth}>{PreAuthItem.created_date}</span>
                      </div>
                      </Grid>
                  </CardContent>
                  {preAuthData?.length > 1 &&
                    index !==
                    preAuthData?.length - 1 && (
                      <Divider
                        variant="middle"
                      />
                    )}
                </Card>
              ))
            ) : (
              <div><NoData message={isLoading ? <img alt='Loading...' src={loader} /> : showMessage} /></div>
            )}
          </div>
        </div>
      </Card>
      <Dialog open={isModalOpen} data-testid='dialogClose' onClose={handleClose} >
        {formsPreAuth[formName]}
      </Dialog>
    </div>
  );
};

export default PreAuthorization;
