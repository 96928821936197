
import React, { useState } from "react";
import { Typography, Button, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Box } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";


const LiquidOxygenSystem = ({ handleClose, preAuthFormData }) => {


    const { control } = useForm();
    const { t } = useTranslation();

    return (
        <div>
            <Dialog fullWidth maxWidth="md" open={true} onClose={handleClose} >
                <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}> Liquid Oxygen System Pre-Authorization Documentation  </DialogTitle>


                <DialogContent>
                    <Typography sx={{ margin: '10px 0px' }}>
                        <span className={styles.asterisk}>*</span> denotes required field
                    </Typography>

                    {
                        preAuthFormData[0]?.BranchCode !== undefined && preAuthFormData[0].BranchCode !== '' &&
                        <Grid container>
                            <Grid xs={12} md={6} >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}> {t("PreAuthForm.branchCode")} <span className={styles.asterisk}> *</span> </Typography>
                                    </div>
                                    <div >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            maxLength={3}
                                            type="text"
                                            value={preAuthFormData[0].BranchCode}
                                            disabled={true}
                                            sx={{
                                                marginTop: '5px',
                                                resize: 'verical',
                                                overflow: 'auto',
                                                maxHeight: '300px',
                                                backgroundColor: "var(--color-disableField)",
                                                borderRadius: "8px",
                                                '& .Mui-disabled': {
                                                    '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                },
                                            }}
                                            inputProps={{
                                                style: {
                                                    cursor: 'not-allowed'
                                                }
                                            }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsPatientOnLiquidOxygenSystem !== undefined &&
                        <Grid container >
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">1. Is the patient currently on a liquid oxygen system?<span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsPatientOnLiquidOxygenSystem}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsLiquidOxygenRefill !== undefined &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. If yes, is this a refill? <span className={styles.asterisk}>*</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsLiquidOxygenRefill}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }


                    {
                        preAuthFormData[0]?.ClinicalRationaleLiquidOxygen !== undefined && preAuthFormData[0].ClinicalRationaleLiquidOxygen !== '' &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        b. If no, what is the Clinical rationale for medical necessity for the use of liquid oxygen?
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="minimum height"
                                    minRows={2}
                                    multiline
                                    rows={3}
                                    maxLength={500}
                                    size="small"
                                    value={preAuthFormData[0].ClinicalRationaleLiquidOxygen}
                                    disabled={true}
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius:'8px',
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsPhysicianOrder !== undefined &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 
                                        2. Is there a physician's order? <span className={styles.asterisk}> *</span>
                                    </FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle: "italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsPhysicianOrder}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsHighFlowOxygenNeeds !== undefined &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">3. Does the patient have high-flow Oxygen needs greater than 8L/min? <span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsHighFlowOxygenNeeds}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsApprovalHighFlowOxygen !== undefined &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                        a. If yes, has the National Medical Director Approved?</FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsApprovalHighFlowOxygen}
                                        disabled={true}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        sx={{ paddingLeft: '15px' }}
                                    >

                                        <Grid container direction="column">
                                            <Grid item>
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                                <Grid container>
                                                    <Grid xs={12} md={6} sx={{ paddingLeft: '15px' }}>
                                                        <div>
                                                            <Typography className={styles.labelText}>
                                                                Medical Director Name
                                                            </Typography>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            aria-label="Name field"
                                                            minRows={2}
                                                            value={preAuthFormData[0]?.NmdName}
                                                            disabled={true}
                                                            size="small"
                                                            maxLength={200}
                                                            sx={{
                                                                marginTop: '5px',
                                                                resize: 'verical',
                                                                overflow: 'auto',
                                                                maxHeight: '300px',
                                                                backgroundColor: "var(--color-disableField)",
                                                                borderRadius: "8px",
                                                                '& .Mui-disabled': {
                                                                    '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                                },
                                                            }}
                                                            inputProps={{
                                                                style: {
                                                                    cursor: 'not-allowed'
                                                                }
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid xs={12} md={6} sx={{ paddingLeft: '15px' }}>
                                                        <div>
                                                            <Typography className={styles.labelText}>
                                                                Approval Date
                                                            </Typography>
                                                        </div>
                                                        <Controller
                                                            name="NmdApprovalDate"
                                                            control={control}
                                                            rules={{ required: true }}
                                                            render={({ field }) => (
                                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                                    <DatePicker slotProps={{ textField: { fullWidth: true, variant: 'outlined' } }} sx={{
                                                                        '& .MuiInputBase-input': {
                                                                            height: '10px',
                                                                        },
                                                                        backgroundColor: 'var(--color-disableField)',
                                                                        borderRadius: "8px",
                                                                        marginTop:"3px",
                                                                        '& .Mui-disabled': {
                                                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                                        },
                                                                    }} name={field.name} value={dayjs(field.value)} inputRef={field.ref} onChange={(newValue) => field.onChange(newValue)} disabled={true} />
                                                                </LocalizationProvider>
                                                            )}
                                                        />
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value="false" control={<Radio />} label="No (Approval must be obtained before submission of this form.)" disabled={true} />
                                            </Grid>
                                        </Grid>

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.CurrentLiterFlowOrdered &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        4. What is the current liter flow ordered? <span className={styles.asterisk}> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="liter flow field"
                                    minRows={2}
                                    value={preAuthFormData[0]?.CurrentLiterFlowOrdered}
                                    disabled={true}
                                    size="small"
                                    maxLength={200}
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsPatientAmbulatory !== undefined &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 5. Is the patient ambulatory? <span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsPatientAmbulatory}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.Pps &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <div>
                                <Grid xs={12} className={styles.spacingTop} sx={{ paddingLeft: '10px' }}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            a. PPS <span className={styles.asterisk}> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        aria-label="minimum height"
                                        rows={3}
                                        maxLength={4000}
                                        size="small"
                                        value={preAuthFormData[0]?.Pps}
                                        disabled={true}
                                        sx={{
                                            marginTop: '5px',
                                            resize: 'verical',
                                            overflow: 'auto',
                                            maxHeight: '300px',
                                            backgroundColor: "var(--color-disableField)",
                                            borderRadius: "8px",
                                            '& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                cursor: 'not-allowed'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid xs={12} className={styles.spacingTop} sx={{ paddingLeft: '10px' }}>
                                    <div>
                                        <Typography className={styles.labelText}>
                                            b. NYHA Class <span className={styles.asterisk}> *</span>
                                        </Typography>
                                    </div>
                                    <TextField
                                        fullWidth
                                        aria-label="minimum height"
                                        rows={3}
                                        maxLength={4000}
                                        size="small"
                                        value={preAuthFormData[0]?.NyhaClass}
                                        disabled={true}
                                        sx={{
                                            marginTop: '5px',
                                            resize: 'verical',
                                            overflow: 'auto',
                                            maxHeight: '300px',
                                            backgroundColor: "var(--color-disableField)",
                                            borderRadius: "8px",
                                            '& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                cursor: 'not-allowed'
                                            }
                                        }}
                                    />
                                </Grid>
                            </div>

                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.OtherExtenuatingCircumstances &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                            <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label">
                                    <div>6.&nbsp;</div>
                                    <div>Are there other extenuating circumstances (medical necessity) that require the patient to use liquid oxygen system?<span className="asteriskSignColor"> *</span>
                                    </div>
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    maxLength={500}
                                    size="small"
                                    value={preAuthFormData[0]?.OtherExtenuatingCircumstances}
                                    disabled={true}
                                    aria-label="minimum height"
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsFacilityRequested !== undefined &&
                        <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">7. Facility has requested? <span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsFacilityRequested}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.IsFacilityRequired !== undefined &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. Is this required by the facility? </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0]?.IsFacilityRequired}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className='paddingLeftFifteenpx'
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.FacilityRequestingName &&
                        <Grid container sx={{ paddingLeft: '15px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        b. Name of requesting facility<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    maxLength={200}
                                    minRows={2}
                                    aria-label="requesting custom text"
                                    size="small"
                                    fullWidth
                                    value={preAuthFormData[0]?.FacilityRequestingName}
                                    disabled={true}
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        Clinician Name <span className={styles.asterisk}> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinic text field"
                                    value={preAuthFormData[0]?.ClinicianName}
                                    disabled={true}
                                    fullWidth
                                    minRows={2}
                                    maxLength={200}
                                    size="small"
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        ED/Admin <span className={styles.asterisk}> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    maxLength={200}
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0]?.AdministratorOrED}
                                    disabled={true}
                                    size="small"
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }

                    {
                        preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        AVPO <span className={styles.asterisk}> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0]?.AVPO}
                                    disabled={true}
                                    size="small"
                                    maxLength={200}
                                    sx={{
                                        marginTop: '5px',
                                        resize: 'verical',
                                        overflow: 'auto',
                                        maxHeight: '300px',
                                        backgroundColor: "var(--color-disableField)",
                                        borderRadius: "8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            cursor: 'not-allowed'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                </DialogContent>

                <Grid container sx={{ justifyContent: 'end' }}>
                    <Grid>
                        <DialogActions style={{ padding: "14px 16px", justifyContent: "end" }} >
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                className='secondaryButton'
                            >
                                {t('NewOrder.close')}
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>

            </Dialog>
        </div>
    )
}

export default LiquidOxygenSystem;