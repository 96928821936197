import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Box, OutlinedInput, InputAdornment, Alert, Button } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import CustomButtons from "../../../common/CustomButtons/CustomButtons";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Ventilator = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();
    return (
        <>
            {preAuthFormData && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={true}
                    onClose={handleClose} >
                    <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>{t("PreAuthForm.ventilatorHeading")}</DialogTitle>
                    <DialogContent>
                        <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor"> *</span> {t("PreAuthForm.requiredField")}</Typography>
                        <Alert icon={false} severity="error" className='marginVerticalTwenty' sx={{ width: '96%' }}>
                            <span className='fontWeight600' style={{ fontSize: "var(--common-heading)" }}>{t("PreAuthForm.ventilatorAlert")} </span>
                        </Alert>
                        <Grid container>
                            <Grid xs={12} md={6}  >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>{t("PreAuthForm.branchCode")}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            maxLength="3"
                                            type="text"
                                            value={preAuthFormData[0].BranchCode}
                                            sx={{ marginTop: '5px', width: "220px", borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              },}}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {preAuthFormData[0]?.IsHospiceCliniciansCompetenciesCompleted !== "" && preAuthFormData[0]?.IsHospiceCliniciansCompetenciesCompleted !== undefined && <>
                            <Typography sx={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold', fontSize: '18px' }}>
                                {t("PreAuthForm.skillCompetencies")}
                            </Typography>
                            <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>1.&nbsp;</div><div>{t('PreAuthForm.IsHospiceCliniciansCompetenciesCompleted')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                        <RadioGroup
                                            className='paddingLeftFifteenpx'
                                            value={preAuthFormData[0].IsHospiceCliniciansCompetenciesCompleted}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        </RadioGroup>
                                        <span style={{ fontStyle: "italic" }}>{t("PreAuthForm.completedCompetenciesNote")}</span>
                                    </FormControl>
                                </Grid>
                            </Grid></>}
                        {preAuthFormData[0]?.IsAgencyOrFacilityCompetenciesCompleted !== "" && preAuthFormData[0]?.IsAgencyOrFacilityCompetenciesCompleted !== null && preAuthFormData[0]?.IsAgencyOrFacilityCompetenciesCompleted !== undefined && <Grid container >
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>2.&nbsp;</div><div>{t('PreAuthForm.IsAgencyOrFacilityCompetenciesCompleted')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsAgencyOrFacilityCompetenciesCompleted}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                    <span style={{ fontStyle: "italic" }}>{t("PreAuthForm.completedCompetenciesNote")}</span>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.PatientDiagnosis !== "" && preAuthFormData[0]?.PatientDiagnosis !== null && preAuthFormData[0]?.PatientDiagnosis !== undefined && <><Typography sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: '18px' }}>
                            {t("PreAuthForm.patientDiagnosisHead")}
                        </Typography>
                            <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. {t("PreAuthForm.patientDiagnosis")}<span className={styles.asterisk}> *</span></FormLabel>
                                        <RadioGroup
                                            className='paddingLeftFifteenpx'
                                            value={preAuthFormData[0].PatientDiagnosis}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="COPD" control={<Radio />} label="COPD" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            <FormControlLabel value="ALS" control={<Radio />} label="ALS" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid></>}

                        {preAuthFormData[0]?.PatientDiagnosis != "" && preAuthFormData[0]?.PatientDiagnosis !== null && preAuthFormData[0]?.PatientDiagnosis !== undefined && <><Typography sx={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold', fontSize: '18px' }}>
                            {t("PreAuthForm.initialApproval")}{preAuthFormData[0]?.PatientDiagnosis == "COPD" ? "COPD" : "ALS"}
                        </Typography>
                            <span>{t("PreAuthForm.limitedTime")}</span></>
                        }
                        {preAuthFormData[0]?.PatientDiagnosis != "" && preAuthFormData[0]?.PatientDiagnosis !== null && preAuthFormData[0]?.PatientDiagnosis !== undefined && preAuthFormData[0]?.PatientDiagnosis == "COPD" ?
                            <Grid container>
                                <Grid xs={12} className={styles.spacingTop}>
                                    <FormControl className={styles.spacingRemoveTop}>
                                        <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. {t("PreAuthForm.severeCopdSigns")}</FormLabel>
                                    </FormControl>
                                </Grid>
                                {preAuthFormData[0]?.IsArterialBloodGasPCO2Above55 !== "" && preAuthFormData[0]?.IsArterialBloodGasPCO2Above55 !== null && preAuthFormData[0]?.IsArterialBloodGasPCO2Above55 !== undefined &&
                                    <Grid container sx={{ paddingLeft: '20px' }}>
                                        <Grid xs={12} className={styles.spacingTop}>
                                            <FormControl className={styles.spacingRemoveTop}>
                                                <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> a. {t("PreAuthForm.IsArterialBloodGasPCO2Above55")} &gt; 55:
                                                    <span className={styles.asterisk}> *</span></FormLabel>
                                                <RadioGroup
                                                    className='paddingLeftFifteenpx'
                                                    value={preAuthFormData[0].IsArterialBloodGasPCO2Above55}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                    <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>}
                                {preAuthFormData[0]?.IsHospitalABGIncreasedCO2 !== "" && preAuthFormData[0]?.IsHospitalABGIncreasedCO2 !== null && preAuthFormData[0]?.IsHospitalABGIncreasedCO2 !== undefined && <Grid container sx={{ paddingLeft: '20px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> b. {t("PreAuthForm.IsHospitalABGIncreasedCO2")}
                                                <span className={styles.asterisk}> *</span></FormLabel>
                                            <RadioGroup
                                                className='paddingLeftFifteenpx'
                                                value={preAuthFormData[0].IsHospitalABGIncreasedCO2}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>}
                                {preAuthFormData[0]?.IsOtherModalitiesFailure !== "" && preAuthFormData[0]?.IsOtherModalitiesFailure !== null && preAuthFormData[0]?.IsOtherModalitiesFailure !== undefined && <Grid container sx={{ paddingLeft: '20px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> c. {t("PreAuthForm.IsOtherModalitiesFailure")}
                                                <span className={styles.asterisk}> *</span></FormLabel>
                                            <RadioGroup
                                                className='paddingLeftFifteenpx'
                                                value={preAuthFormData[0].IsOtherModalitiesFailure}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>}
                                {preAuthFormData[0]?.IsSleepApneaConsideredRuledOut !== "" && preAuthFormData[0]?.IsSleepApneaConsideredRuledOut !== null && preAuthFormData[0]?.IsSleepApneaConsideredRuledOut !== undefined && <Grid container sx={{ paddingLeft: '20px' }}>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> d. {t("PreAuthForm.IsSleepApneaConsideredRuledOut")}
                                                <span className={styles.asterisk}> *</span></FormLabel>
                                            <RadioGroup
                                                className='paddingLeftFifteenpx'
                                                value={preAuthFormData[0].IsSleepApneaConsideredRuledOut}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>}
                            </Grid>
                            :
                            <> {preAuthFormData[0]?.IsRefusedAntibiotics !== "" && preAuthFormData[0]?.IsRefusedAntibiotics !== null && preAuthFormData[0]?.IsRefusedAntibiotics !== undefined && (
                                <Grid container>
                                    <Grid xs={12} className={styles.spacingTop}>
                                        <FormControl className={styles.spacingRemoveTop}>
                                            <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">
                                                1. {t("PreAuthForm.IsRefusedAntibiotics")}
                                                <span className={styles.asterisk}> *</span>
                                            </FormLabel>
                                            <RadioGroup
                                                className='paddingLeftFifteenpx'
                                                value={preAuthFormData[0]?.IsRefusedAntibiotics}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" disabled sx={{ cursor: "not-allowed" }} />
                                                <FormControlLabel value="false" control={<Radio />} label="No" disabled sx={{ cursor: "not-allowed" }} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                            </>
                        }
                        {preAuthFormData[0]?.IsPatientReturningHospitalEr !== "" && preAuthFormData[0]?.IsPatientReturningHospitalEr !== null && preAuthFormData[0]?.IsPatientReturningHospitalEr !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 2. {t("PreAuthForm.IsPatientReturningHospitalEr")}
                                        <span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsPatientReturningHospitalEr}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.TrilogyTypeOrdered !== "" && preAuthFormData[0]?.TrilogyTypeOrdered !== null && preAuthFormData[0]?.TrilogyTypeOrdered !== undefined && <Grid container >
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. {t("PreAuthForm.TrilogyTypeOrdered")}<span className={styles.asterisk}> *</span></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].TrilogyTypeOrdered}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Invasive" control={<Radio />} label="Invasive (trach)" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="Non-invasive" control={<Radio />} label="Non-invasive (mask)" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.TrilogyOrderedSettings && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Typography className={styles.labelText}>
                                    4. {t("PreAuthForm.TrilogyOrderedSettings")} <span className={styles.asterisk}> *</span>
                                </Typography>
                                <TextField
                                    fullWidth
                                    aria-label="trilogy order settings"
                                    minRows={1}
                                    multiline
                                    value={preAuthFormData[0].TrilogyOrderedSettings}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AnticipatedDischargeDate && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        5. {t("PreAuthForm.dischargeDate")} <span className={styles.asterisk}>*</span><br /> <span style={{ fontStyle: "italic", marginLeft: '15px'}}>{t("PreAuthForm.dischangeDateNote")}</span>
                                    </Typography>
                                </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker slotProps={{ textField: { variant: 'outlined' } }} sx={{
                                        '& .MuiInputBase-input': {
                                            height: '10px',
                                        },
                                        marginTop: '5px',
                                        backgroundColor: 'var(--color-disableField)',
                                        marginLeft:'15px',
                                        borderRadius:"8px",
                                        '& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                        }
                                    }} disabled={true} name={preAuthFormData[0].DischargeDate} value={dayjs(preAuthFormData[0].DischargeDate)} />
                                </LocalizationProvider>

                            </Grid>
                        </Grid>}
                        <Typography sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: '18px' }}>
                            {t("PreAuthForm.physicianOrderHeading")}
                        </Typography>
                        {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 1. {t("PreAuthForm.physicianOrder")} <span className={styles.asterisk}>*</span></FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle: "italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsPhysicianOrder}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsNmdApproval && <Grid container sx={{ paddingLeft: '20px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">a. {t("PreAuthForm.NmdName")} </FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsNmdApproval}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <Grid container>
                                            <Grid xs={12} md={6} sx={{ paddingLeft: '10px' }}>
                                                <Typography className={styles.labelText}>
                                                    {t("PreAuthForm.medicalDirectorName")}
                                                </Typography>
                                                <TextField
                                                    aria-label="nmd name"
                                                    minRows={1}
                                                    fullWidth
                                                    multiline
                                                    value={preAuthFormData[0].NmdName}
                                                    size="small"
                                                    maxLength={200}
                                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                      },}}
                                                    disabled={true}
                                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                                />
                                            </Grid>
                                            <Grid xs={12} md={6} sx={{ paddingLeft: '10px' }}>
                                                <Typography className={styles.labelText}>
                                                    {t("PreAuthForm.approvalDate")}
                                                </Typography>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DatePicker slotProps={{ textField: { fullWidth: true, variant: 'outlined' } }} sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '7px',
                                                        },
                                                        borderRadius:"8px",
                                                        marginTop: '5px',
                                                        backgroundColor: 'var(--color-disableField)',
                                                        '& .Mui-disabled': {
                                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                                      },
                                                    }} 
                                                    disabled={true} name={preAuthFormData[0].NmdApprovalDate} value={dayjs(preAuthFormData[0].NmdApprovalDate)} />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                        <FormControlLabel value="false" control={<Radio />} label={<span style={{ display: "inline-flex", alignItems: "center", whiteSpace: "nowrap" }}>
                                            No&nbsp;
                                            <span style={{ fontStyle: "italic" }}>
                                                (Approval must be obtained before submission of this form. Ventilator Referral Form must be completed.)
                                            </span>
                                        </span>} disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.OtherInformationToProvide !== "" && preAuthFormData[0]?.OtherInformationToProvide !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Typography className={styles.labelText}>
                                    2. {t("PreAuthForm.OtherInformationToProvide")}
                                </Typography>
                                <TextField
                                    fullWidth
                                    aria-label="Other Information To Provide"
                                    minRows={3}
                                    multiline
                                    value={preAuthFormData[0].OtherInformationToProvide}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.clinicalName')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinic text field"
                                    value={preAuthFormData[0].ClinicianName}
                                    fullWidth
                                    minRows={2}
                                    maxLength={200}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.edAdmin")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    maxLength={200}
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0].AdministratorOrED}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.avpo")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0].AVPO}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        <Typography sx={{ marginTop: '20px', fontStyle:"italic"  }}>
                            {t("PreAuthForm.trilogyNote")}
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "end", padding: "14px 16px" }}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className='secondaryButton'
                        >
                            {t('NewOrder.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

Ventilator.formName = "Ventilator";
export default Ventilator;