import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import BillingMonthFilter from '../generic-filter-reports-component/billing-month-filter/billing-month-filter';
import dayjs from 'dayjs';
import GenericAccordionCard from '../../../common/Utils/generic-accordion-card/generic-accordion-card';
import styles from './equipment-quantity-utilization-report.module.scss';
import { ReportsApi, Support } from '../../../constants/ApiConstants';
import { HTTPMethod, callApi as reportsRequest } from "../../../services/HttpService/HttpService";
import { useTranslation } from 'react-i18next';
import CustomGrid from '../../../components/CustomGrid';
import NoData from '../../../components/noData/noData';
import loader from "../../../../src/assets/loader.gif";
import { CompanyName } from '../generic-filter-reports-component/company-name-filter/CompanyName';
import CustomTablePagination, { CustomPagination } from '../../../components/CustomTablePagination/CustomTablePagination';
import LocationFilter from './../generic-filter-reports-component/location-filter/location-filter';
import withTitle from '../../../components/withTitle/withTitle';
import reportStyle from './../reports.module.scss';
import ExportReportBtn from '../generic-filter-reports-component/ExportReportBtn/ExportReportBtn';
import { formatReportDate } from '../../../common/Utils/CommonFunctions/CommonFunctions';

const EquipmentQuantityUtilizationReport = () => {
  localStorage.setItem("drawerSelectedItem", "Reports");

  const [selectedBillingDate, setSelectedBillingDate] = useState(dayjs());
  const { t } = useTranslation();
  const [equipmentReportList, setEquipmentReportList] = useState([]);
  const [companyDataName, setCompanyDataName] = useState(null);
  const [companyOption, setCompanyOption] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(10);
  const [isPaginationLoading, setPaginationLoading] = useState(false);
  const [addBranchOption, setAddBranchOption] = useState([]);
  const [orderBranchId, setOrderBranchId] = useState([]);
  const { page, rowsPerPage, setPage, setOffset, setRowsPerPage, handleChangePage, handleChangeRowsPerPage } = CustomPagination();
  const [isChipDeleteClick, setChipDeleteClick] = useState(false);
  const [validBillingDate, setValidBillingDate ] = useState(false);
  const [isExportLoading, setExportLoading] = useState(false);

  const [filterValues, setFilterValues] = useState({
    company: '',
    branch: 'All',
    billingDate: formatReportDate(dayjs()),
  });
  const [searchSummary, setSearchSummary] = useState("");
  const defaultChipValues = ["Branch: All", `Billing Month: ${formatReportDate(dayjs())}`]

  useEffect(() => {
    const fetchCompanyData = async () => {
      const response = await reportsRequest(HTTPMethod.Get, Support.hospices);
      if (response && response.status_code === 200 && response.data) {
        setCompanyOption(response?.data?.providers);
        setCompanyDataName(response?.data?.providers?.[0]);
      }
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    if (companyDataName) {
      handleSearch();
    }
  }, [companyDataName, page, rowsPerPage]);

  useEffect(() => {
    setTotalRecords(equipmentReportList?.length);
  }, [equipmentReportList]);

  useEffect(() => {
    if (isChipDeleteClick) {
      handleSearch();
      setOffset(1);
      setPage(0);
      setChipDeleteClick(false);
    }
  }, [isChipDeleteClick]);

  const handleChangePatientsFilter = (e, fieldName, filterValue) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [fieldName]: (filterValue || e.target.value),
    }));
  };
  const props = { addBranchOption, setAddBranchOption, orderBranchId, setOrderBranchId, handleChangePatientsFilter, defaultChipValues}

  const handleReset = () => {
    setSelectedBillingDate(dayjs());
    setCompanyDataName(companyOption?.[0]);
    setSearchSummary("");
    setFilterValues({
      company: '',
      branch: 'All',
      billingDate: formatReportDate(dayjs()),
    });
    setOrderBranchId(addBranchOption[0]);
    setRowsPerPage(25);
    setPage(0);
    setOffset(1);
    handleSearch(true)
  };

  const handleSearch = async (isReset = false) => {
    setLoading(true);
    setPaginationLoading(true);
    setEquipmentReportList([]);
    const summaryParts = [];
    if (isReset) {
      summaryParts.push(
        'Branch: All',
        `Billing Month: ${formatReportDate(dayjs())}`
      );
      equipmentUtilizationApi(true);
    }
    else {
      if (filterValues.company) {
        summaryParts.push(`Company: ${filterValues.company}`);
      }
      if (filterValues.branch) {
        summaryParts.push(`Branch: ${filterValues.branch}`);
      }
      if (filterValues.billingDate) {
        summaryParts.push(`Billing Month: ${filterValues.billingDate}`);
      }
      equipmentUtilizationApi();
    }
    setSearchSummary(summaryParts);
  };

  const equipmentUtilizationApi = async(isReset = false) => {
    const year = selectedBillingDate.year();
    const month = selectedBillingDate.month() + 1;

    let requestBody = {
      client_id: orderBranchId?.location_id || null,
      hospice_id: companyDataName?.company_id || null,
      year: year,
      month: month,
    };
    if(isReset) {
      requestBody = {
        client_id: 0,
        hospice_id: companyDataName?.company_id || null,
        year: dayjs().year(),
        month: dayjs().month() + 1,
      };
    }

    const response = await reportsRequest(
      HTTPMethod.Post,
      ReportsApi.equipmentQuantityReport,
      requestBody
    );
    if (response && response.status_code === 200 && response.data) {
      setEquipmentReportList(response.data.equipment_qty_utilization);
      setLoading(false);
      setPaginationLoading(false);
    }
  }
  const columnsRecentOrder = [
    {
      field: "product",
      headerName: "Product",
      width: 940,
      flex: 1,
      renderCell: (params) => (params?.row?.product ? params.row.product : " "),
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 240,
      flex: 0.5,
      renderCell: (params) => (params?.row?.qty ? params.row.qty : "0"),
    },
  ];
  const getRecentOrderRowId = (row, index) => `${row.product}-${index}`;

  const paginatedData = equipmentReportList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className={`${styles.EquipmentQuantityUtilization} ${reportStyle.reportItemContainer}`} data-testid="EquipmentQuantityUtilizationReport">
      <Box>
        <CssBaseline />
          <Grid item sm={6}>
            <div className={styles.mainHeadingText}> {t("reports.equipmentQuantityUtilization")}</div>
          </Grid>
          <div className={reportStyle.filterContainer}>
            <div className={reportStyle.filter}>
              <GenericAccordionCard
                title="Equipment Quantity Utilization"
                onReset={handleReset}
                onSearch={handleSearch}
                searchSummary={searchSummary}
                setSearchSummary={setSearchSummary}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                setOrderBranchId={setOrderBranchId}
                setSelectedBillingDate={setSelectedBillingDate}
                isChipDeleteClick={isChipDeleteClick}
                setChipDeleteClick={setChipDeleteClick}
                validBillingDate = {validBillingDate}
                props={props}
              >
                {companyOption?.length > 1 &&
                  <div className='marginBottomTwenty'>
                    <CompanyName
                      companyOption={companyOption}
                      companyDataName={companyDataName}
                      setCompanyDataName={setCompanyDataName}
                      handleChangePatientsFilter={handleChangePatientsFilter}
                    />
                  </div>
                }
                <div className='marginBottomTwenty'>
                  <LocationFilter props={props} /></div>
                <div className='marginBottomTwenty'>
                  <BillingMonthFilter
                   validBillingDate = {validBillingDate}
                   setValidBillingDate = {setValidBillingDate}
                    selectedBillingDate={selectedBillingDate}
                    setSelectedBillingDate={setSelectedBillingDate}
                    data-testid="billingMonthFilter"
                    handleChangePatientsFilter={handleChangePatientsFilter} />
                </div>
              </GenericAccordionCard>
            </div>
            <div>
              <ExportReportBtn
                reportUrl= {ReportsApi.equipmentQuantityExport}
                reportRequsetBody={{
                  client_id: orderBranchId?.location_id || null,
                  hospice_id: companyDataName?.company_id || null,
                  year: selectedBillingDate.year(),
                  month: selectedBillingDate.month() + 1,
                }}
                reportMethod={HTTPMethod.Post}
                exportLoading={setExportLoading}
                filenamePrefix="EquipmentQuantityUtilization"
              /></div>
          </div>
          {isExportLoading && <NoData message={<img alt='Loading...' src={loader} />} />}
          <div data-testid="tableView" style={{ marginTop: "2.5rem" }}>
            {equipmentReportList?.length > 0 ? (
              <CustomGrid
                data={{
                  showSlots: false,
                  rows: paginatedData,
                  columns: columnsRecentOrder,
                  getRowId: getRecentOrderRowId,
                  page: page,
                  rowsPerPage: rowsPerPage,
                }}
              />
            ) : (<NoData
              message={
                  isLoading ? (
                      <img alt="Loading..." src={loader} />) : (
                      t("reports.noRecordsAvailable")
                  )
              }
          />
            )}
            {equipmentReportList?.length > 0 && (<div className={styles.pagination}>
              <CustomTablePagination
                totalRecord={totalRecords}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                isLoading={isPaginationLoading}
              />
            </div>
            )}
          </div>
      </Box>
    </div>
  );
};
export default withTitle(EquipmentQuantityUtilizationReport, "Equipment Quantity Utilization");