import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Button, Box } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css';
import { useTranslation } from "react-i18next";

const HTRTiltAndBroda = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();
    return (
        <>
            {preAuthFormData && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={true}
                    onClose={handleClose} >
                    <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>{t("PreAuthForm.HtrTiltHeading")}</DialogTitle>
                    <DialogContent>
                        <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor"> *</span> {t("PreAuthForm.requiredField")}</Typography>
                        <Grid container>
                            <Grid xs={12} md={6}  >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>{t("PreAuthForm.branchCode")}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            maxLength="3"
                                            type="text"
                                            value={preAuthFormData[0].BranchCode}
                                            sx={{ marginTop: '5px', width: "220px", borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              },}}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {preAuthFormData[0]?.FormularyItemsTriedBeforeBroda !== "" && preAuthFormData[0]?.FormularyItemsTriedBeforeBroda !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>1.&nbsp;</div><div>{t('PreAuthForm.FormularyItemsTriedBeforeBroda')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                <TextField
                                    fullWidth
                                    aria-label="Formulary Items Tried Before Broda"
                                    minRows={3}
                                    multiline
                                    value={preAuthFormData[0].FormularyItemsTriedBeforeBroda}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPatientSelfMobile !== "" && preAuthFormData[0]?.IsPatientSelfMobile !== null && preAuthFormData[0]?.IsPatientSelfMobile !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">2. {t("PreAuthForm.IsPatientSelfMobile")} <span className={styles.asterisk}>*</span></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsPatientSelfMobile}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPositioningAssistanceRequired !== "" && preAuthFormData[0]?.IsPositioningAssistanceRequired !== null && preAuthFormData[0]?.IsPositioningAssistanceRequired !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 3. {t("PreAuthForm.IsPositioningAssistanceRequired")}<span className={styles.asterisk}>*</span></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsPositioningAssistanceRequired}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsTiltInSpaceRequired !== "" && preAuthFormData[0]?.IsTiltInSpaceRequired !== null && preAuthFormData[0]?.IsTiltInSpaceRequired !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>4.&nbsp;</div><div>{t('PreAuthForm.IsTiltInSpaceRequired')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                         className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsTiltInSpaceRequired}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsTrunkMusculoskeletalDeformity !== "" && preAuthFormData[0]?.IsTrunkMusculoskeletalDeformity !== null && preAuthFormData[0]?.IsTrunkMusculoskeletalDeformity !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>5.&nbsp;</div><div>{t('PreAuthForm.IsTrunkMusculoskeletalDeformity')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsTrunkMusculoskeletalDeformity}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsSkinIntegrityRisk !== "" && preAuthFormData[0]?.IsSkinIntegrityRisk !== null && preAuthFormData[0]?.IsSkinIntegrityRisk !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>6.&nbsp;</div><div>{t('PreAuthForm.IsSkinIntegrityRisk')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsSkinIntegrityRisk}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsMinimumSixHoursInWheelchair !== "" && preAuthFormData[0]?.IsMinimumSixHoursInWheelchair !== null && preAuthFormData[0]?.IsMinimumSixHoursInWheelchair !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">7. {t("PreAuthForm.IsMinimumSixHoursInWheelchair")}<span className={styles.asterisk}>*</span></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsMinimumSixHoursInWheelchair}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.WheelchairHoursDescription && <Grid container sx={{ paddingLeft: '20px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Typography className={styles.labelText}>
                                    a. {t("PreAuthForm.pleaseDescribe")}
                                </Typography>
                                <TextField
                                    fullWidth
                                    aria-label="wheel chair hours description"
                                    minRows={3}
                                    multiline
                                    value={preAuthFormData[0].WheelchairHoursDescription}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsDysfunctionImprovedByTilt !== "" && preAuthFormData[0]?.IsDysfunctionImprovedByTilt !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>8.&nbsp;</div><div>{t('PreAuthForm.IsDysfunctionImprovedByTilt')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsDysfunctionImprovedByTilt}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.DysfunctionImprovementDescription && <Grid container sx={{ paddingLeft: '20px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Typography className={styles.labelText}>
                                    a. {t("PreAuthForm.pleaseDescribe")}
                                </Typography>
                                <TextField
                                    fullWidth
                                    aria-label="dysfunction improvement description"
                                    minRows={3}
                                    multiline
                                    value={preAuthFormData[0].DysfunctionImprovementDescription}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 9. {t("PreAuthForm.physicianOrder")} <span className={styles.asterisk}>*</span></FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle: "italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsPhysicianOrder}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsExtenuatingCircumstancesForBroda !== "" && preAuthFormData[0]?.IsExtenuatingCircumstancesForBroda !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>10.&nbsp;</div><div>{t('PreAuthForm.IsExtenuatingCircumstancesForBroda')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsExtenuatingCircumstancesForBroda}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.ExtenuatingCircumstancesDescription && <Grid container sx={{ paddingLeft: '20px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Typography className={styles.labelText}>
                                    a. {t("PreAuthForm.pleaseDescribe")}
                                </Typography>
                                <TextField
                                    fullWidth
                                    aria-label="Extenuating Circumstances description"
                                    minRows={3}
                                    multiline
                                    value={preAuthFormData[0].ExtenuatingCircumstancesDescription}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>
                        }
                        {preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.clinicalName')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinic text field"
                                    value={preAuthFormData[0].ClinicianName}
                                    fullWidth
                                    minRows={2}
                                    maxLength={200}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.edAdmin")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    maxLength={200}
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0].AdministratorOrED}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.avpo")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0].AVPO}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "end", padding: "14px 16px"}}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className='secondaryButton'
                        >
                            {t('NewOrder.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

HTRTiltAndBroda.formName = "HTRTiltAndBroda";
export default HTRTiltAndBroda;