import React, { useEffect, useState } from 'react';
import styles from '../SilentTransfer.module.scss';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InventoryIcon from '@mui/icons-material/Inventory';
import WheelchairPickupIcon from "@mui/icons-material/WheelchairPickup";
import { HTTPMethod, callApi as silentTransferRequest } from '../../../../services/HttpService/HttpService';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { commonConstants } from '../../../../common/constants';
import { LocationStateApi, PatientsApi } from '../../../../constants/ApiConstants';
import GenericEquipmentTags from '../../../../common/Utils/GenericEquipmentTags/GenericEquipmentTags';
import { useSelector } from 'react-redux';

const EquipmentTransfer = ({patientId, equipmentData, setEquipmentData, isLoading, setIsLoading, branchId}) => {
  const { t } = useTranslation();
  const [expandedStateEquipment, setExpandedStateEquipment] = useState([]);
  const [preAuthBranchSetting, setPreAuthBranchSetting] = useState(null);
  const userId = useSelector(state => state.authState.userData?.data?.user_info?.user_id ?? 0);
  const userName = useSelector(state => state.authState.userData?.data?.user_info?.username ?? "");

  const toggleEquipmentExpanded = (index) => {
    const newExpandedStates = [...expandedStateEquipment];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStateEquipment(newExpandedStates);
  };

  const handleEquipmentData = async () => {
    setIsLoading(true);
    const response = await silentTransferRequest(HTTPMethod.Get, PatientsApi.patientsEquipments.concat(`?PatientId=${patientId}&UserId=${userId}&UserName=${userName}`));
    if (response && response.status_code == 200) {
      const equipment = response?.data?.patient_equipment?.map(item => ({ ...item, patient_equipment: item?.patient_equipment?.filter(val => val?.equipment_status == "Delivered" && val?.consumable == false) })).filter(item => item?.patient_equipment?.length > 0);
      setEquipmentData(equipment); 
      const initialExpandedStates = equipment?.map(() => true) ?? [];
      setExpandedStateEquipment(initialExpandedStates);
    }
     setIsLoading(false)
  }

  const handleBranchSettingsPreAuthTags = async () => {
    const response = await silentTransferRequest(HTTPMethod.Get, `${LocationStateApi.orderSettingsForBranch}?branch_id=${branchId}`);
    if (response && response.status_code == 200 && response.data) {
      setPreAuthBranchSetting(response.data?.setting_list);
    }
  }
 
  useEffect(() => {
    handleEquipmentData();
    handleBranchSettingsPreAuthTags();
  }, []);
  return(
    <div data-testid="EquipmentTransfer">
      {!isLoading && equipmentData && equipmentData?.length > 0 &&
        <>
          {equipmentData.map((equipmentItem, index) => {
            return (
              <>
                {equipmentItem && 
                  <Card variant="outlined" className={styles.customBox2} data-testid="EquipmentTransferred">
                    <Box className={`${styles.scopedClassName} ${styles.equipmentAddress}`} >
                      <div className={styles.divContainer}>
                        <div className={styles.container}>
                          <span className={styles.personIcon}><WheelchairPickupIcon /></span>
                          <span className={styles.zipCode}>
                            {t('SilentTransfer.equipmentToBeTransferred')}
                            &nbsp;</span>
                        </div>
                        <div className={styles.wordWrap}>
                          {equipmentItem.address_line1 && (
                            <span>{equipmentItem.address_line1},&nbsp;</span>
                          )}
                          {equipmentItem.address_line2 && (
                            <span>{equipmentItem.address_line2},&nbsp;</span>
                          )}
                          {(equipmentItem.city || equipmentItem.state || equipmentItem.zip_code) && <span>{equipmentItem.city}, {equipmentItem.state}, {equipmentItem.zip_code},&nbsp;</span>}
                          {equipmentItem.country && <span>{equipmentItem.country}</span>}
                        </div>
                      </div>
                      {expandedStateEquipment[index] && <ExpandLessIcon data-testid="ExpandLessIcon" onClick={() => { toggleEquipmentExpanded(index) }} className={styles.expandStyle} />}
                      {!expandedStateEquipment[index] && <ExpandMoreIcon data-testid="ExpandMoreIcon" onClick={() => { toggleEquipmentExpanded(index) }} className={styles.expandStyle} />}
                    </Box>
                    {expandedStateEquipment[index] && <> {equipmentItem?.patient_equipment?.map((item) => {
                      return (
                        <CardContent
                          key={item?.orders}
                          className={styles.cardContainer}>
                          <div
                            key={index}
                            className={styles.dataMargin}>
                            <Grid container className={styles.gridClass}>
                              <Grid item md={2} xs={12}>
                                {item?.equipment_image == "" ? (
                                  <InventoryIcon className={styles.inventoryIconStyle} />
                                ) : (<img
                                  src={item?.equipment_image}
                                  alt="Order Image"
                                  className={styles.equipmentImageStyle}
                                />)}
                              </Grid>
                              <Grid item md={7} xs={12} className={styles.paddingTop20px}>
                                <div className={styles.tags}>
                                  <h3 className={styles.equipmentName}>
                                    {item?.equipment_name}
                                  </h3>
                                  <GenericEquipmentTags item={item?.equipment_type_tag} settingForBranch={preAuthBranchSetting}/>
                                </div>
                                {item.orders && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.order")}
                                    </span>
                                    <span>
                                      <RouterLink to={`/orders/order-details/${item.orders}`} className={styles.customLink}
                                        data-testid={"orderId" + index}
                                      >
                                        {item.orders}
                                      </RouterLink>
                                    </span>
                                  </Typography>
                                )}
                                {!item.provider.provider_name && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.provider")}
                                    </span>
                                    <span
                                      key={index}
                                      className={styles.content2}>
                                      {item.provider[0]?.provider_name}
                                    </span>
                                  </Typography>
                                )}
                                {item.ordered_on !== commonConstants.defaultDate && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.orderedOn")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.ordered_on}
                                    </span>
                                  </Typography>
                                )}
                                {item.approved_on !== commonConstants.defaultDate && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.approvedOn")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.approved_on}
                                    </span>
                                  </Typography>
                                )}
                                {item.approved_by && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.approvedBy")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.approved_by}
                                    </span>
                                  </Typography>
                                )}
                                {item.delivered_on !== commonConstants.defaultDate && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.deliveredOn")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.delivered_on}
                                    </span>
                                  </Typography>
                                )}
                                {item.reference_order != null && item.reference_order !== 0 &&
                                  <Typography>
                                    <span className={styles.content}>
                                      {t('Dashboard.refernceOrderId')}:
                                    </span>
                                    <span>
                                      <RouterLink data-testid={"ReferenceOrder" + index} to={`/orders/order-details/${equipmentItem.reference_order}`} className={styles.customLink}
                                      >
                                        {item.reference_order}
                                      </RouterLink>
                                    </span>
                                  </Typography>
                                }
                                {item.picked_up_on !== commonConstants.defaultDate && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t('SilentTransfer.pickedUpOn')}:
                                    </span>
                                    <span className={styles.content2}>
                                      {item.picked_up_on}
                                    </span>
                                  </Typography>

                                )}
                                {item.asset_number && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.asset")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.asset_number}
                                    </span>
                                  </Typography>
                                )}
                                {item.serial_number && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.serial")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.serial_number}
                                    </span>
                                  </Typography>
                                )}
                                {item.lot_number && (
                                  <Typography>
                                    <span className={styles.content}>
                                      {t("EquipmentsTab.lot")}
                                    </span>
                                    <span className={styles.content2}>
                                      {item.lot_number}
                                    </span>
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </CardContent>
                      )
                    })}</>}
                  </Card>
                }
              </>
            )
          })}
        </>}
  </div>
  )
};

export default EquipmentTransfer;