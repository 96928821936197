import React from "react";
import { Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Grid, Dialog, DialogActions, TextField, DialogContent, DialogTitle, Box, OutlinedInput, InputAdornment, Button } from "@mui/material";
import styles from '../../PreAuthForms/PreAuthForms/FormAuth.module.css'
import { useTranslation } from "react-i18next";
import { formatHeight } from "../../../common/Utils/CommonFunctions/CommonFunctions";
import { LineOfBusinessEnum } from "../../../constants/BusinessConstants";

const BariatricSupport = ({ handleClose, preAuthFormData }) => {
    const { t } = useTranslation();
    return (
        <>
            {preAuthFormData && (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={true}
                    onClose={handleClose} >
                    <DialogTitle sx={{ m: 0, p: 2 }} className={styles.DialogTitle}>{t("PreAuthForm.bariatricSupport")}</DialogTitle>
                    <DialogContent>
                        <Typography sx={{ margin: '10px 0px' }}><span className="asteriskSignColor"> *</span> {t("PreAuthForm.requiredField")}</Typography>
                        <Grid container>
                            <Grid  xs={12} md={6}  >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>{t("PreAuthForm.branchCode")}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            maxLength="3"
                                            type="text"
                                            value={preAuthFormData[0].BranchCode}
                                            sx={{ marginTop: '5px', width: "220px", borderRadius:"8px", backgroundColor: 'var(--color-disableField)', '& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              },}}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {preAuthFormData[0]?.PatientHeight && <Grid container>
                            <Grid item xs={12} md={6} className={styles.spacingTop} >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>1. {t('PreAuthForm.patientsHeight')}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={formatHeight(preAuthFormData[0].PatientHeight)}
                                            sx={{ marginTop: '5px', width: "220px", borderRadius:"8px",  backgroundColor: 'var(--color-disableField)','& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              }, }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                        />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.PatientWeight && <Grid container>
                            <Grid item xs={12} md={6} className={styles.spacingTop} >
                                <FormControl className={styles.spacingRemoveTop}>
                                    <div>
                                        <Typography sx={{ margin: '10px 0px' }} className={styles.labelText}>2. {t('PreAuthForm.patientsWeight')}<span className="asteriskSignColor"> *</span></Typography>
                                    </div>
                                    <div>
                                        <OutlinedInput 
                                            margin="normal" 
                                            fullWidth
                                            size="small"
                                            autoFocus
                                            variant="outlined"
                                            id="name"
                                            type="text"
                                            value={preAuthFormData[0].PatientWeight}
                                            sx={{ marginTop: '5px', width: "220px", borderRadius:"8px", backgroundColor: 'var(--color-disableField)','& .Mui-disabled': {
                                                '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                              }, }}
                                            disabled={true}
                                            inputProps={{ style: { cursor: 'not-allowed' } }}
                                            endAdornment={<InputAdornment position="end">{t('PreAuthForm.lbs')}</InputAdornment>}
                                            />
                                    </div>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsStageIIPressureAreasNotImproved !== "" && preAuthFormData[0]?.IsStageIIPressureAreasNotImproved !== undefined && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>3.&nbsp;</div><div>{t("PreAuthForm.patientHasStage2PressureAreas")}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        className='paddingLeftFifteenpx'
                                        value={preAuthFormData[0].IsStageIIPressureAreasNotImproved}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                    <Box component="p" sx={{ paddingLeft:"19px" }} className={`${styles.labelText} marginBottomZero displayFlex`}><div>a.&nbsp;</div><div>{t("PreAuthForm.bariatricUlcerTreatment")}:</div></Box>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsWeeklyUlcerAssessment !== "" && preAuthFormData[0]?.IsWeeklyUlcerAssessment !== null && preAuthFormData[0]?.IsWeeklyUlcerAssessment !== undefined  && <Grid container sx={{ paddingLeft: '37px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>i.&nbsp;</div><div>{t("PreAuthForm.bariatricWeeklyAccessment")}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsWeeklyUlcerAssessment}
                                        row
                                         className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsScheduledTurningPositioning  !== "" && preAuthFormData[0]?.IsScheduledTurningPositioning  !== null  && preAuthFormData[0]?.IsScheduledTurningPositioning !== undefined  && <Grid container sx={{ paddingLeft: '37px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>ii.&nbsp;</div><div>{t('PreAuthForm.bariatricScheduledTurning')}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsScheduledTurningPositioning}
                                        row
                                         className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsWoundCarePerOrders !== "" && preAuthFormData[0]?.IsWoundCarePerOrders !== null && preAuthFormData[0]?.IsWoundCarePerOrders !== undefined  && <Grid container sx={{ paddingLeft: '37px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>iii.&nbsp;</div><div>{t("PreAuthForm.bariatricWoundCare")}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsWoundCarePerOrders}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsMoistureIncontinenceManagement !== "" && preAuthFormData[0]?.IsMoistureIncontinenceManagement !== null  && preAuthFormData[0]?.IsMoistureIncontinenceManagement !== undefined  && <Grid container sx={{ paddingLeft: '37px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>iv.&nbsp;</div><div>{t('PreAuthForm.bariatricScheduleManagement')}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsMoistureIncontinenceManagement}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsNutritionalAssessmentIntervention !== "" && preAuthFormData[0]?.IsNutritionalAssessmentIntervention !== null && preAuthFormData[0]?.IsNutritionalAssessmentIntervention !== undefined  && <Grid container sx={{ paddingLeft: '37px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>v.&nbsp;</div><div>{t('PreAuthForm.bariatricNutritional')}<span className="asteriskSignColor"> *</span></div>
                                </FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsNutritionalAssessmentIntervention}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.WoundLocationDetails && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid xs={12} className={styles.spacingTop} >
                                <div>
                                <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>b.&nbsp;</div><div>{t("PreAuthForm.bariatricLocationWound")}<span className="asteriskSignColor">&nbsp;*</span></div>
                                        </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="wound location field"
                                    minRows={2}
                                    multiline
                                    value={preAuthFormData[0].WoundLocationDetails}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsLargeStageIIIorIVUlcers !== "" && preAuthFormData[0]?.IsLargeStageIIIorIVUlcers !== undefined  && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>4.&nbsp;</div><div>{t("PreAuthForm.isStageIIIorIVPressureUlcers").replace('#patient', LineOfBusinessEnum.patientToMember.toLowerCase())}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsLargeStageIIIorIVUlcers}
                                        className='paddingLeftFifteenpx'
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.StageIIIorIVWoundDetails && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>a.&nbsp;</div><div>{t("PreAuthForm.bariatricLocationOfTheWound")}<span className="asteriskSignColor"> *</span></div>
                                        </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="wound location field"
                                    minRows={2}
                                    multiline
                                    value={preAuthFormData[0].StageIIIorIVWoundDetails}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsRecentSkinGraftOrFlap !== "" && preAuthFormData[0]?.IsRecentSkinGraftOrFlap !== undefined  && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                <FormLabel className={`${styles.labelText} displayFlex`} id="demo-row-radio-buttons-group-label"><div>5.&nbsp;</div><div>{t('PreAuthForm.bariatricMycotaneousFlap')}<span className="asteriskSignColor"> *</span></div></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsRecentSkinGraftOrFlap}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.SurgeryAndDischargeDate && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <Grid >
                                <Typography className={`${styles.labelText} displayFlex`}>
                                            <div>a.&nbsp;</div><div>{t('PreAuthForm.bariatricDischargeDate')}:<span className="asteriskSignColor"> *</span></div>
                                        </Typography>
                                    <TextField
                                        fullWidth
                                        minRows={2}
                                        maxLength={50}
                                        size="small"
                                        value={preAuthFormData[0].SurgeryAndDischargeDate}
                                        sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                          },}}
                                        disabled={true}
                                        inputProps={{ style: { cursor: 'not-allowed' } }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsPhysicianOrder !== "" && preAuthFormData[0]?.IsPhysicianOrder !== undefined  && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label"> 6. {t('PreAuthForm.physicianOrder')}<span className="asteriskSignColor"> *</span> </FormLabel>
                                    <Box component="span" sx={{ fontWeight: 'bold', fontStyle:"italic", marginLeft: '18px', marginTop:'4px' }}>{t('PreAuthForm.mdOrder')}</Box>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsPhysicianOrder}
                                        row
                                        className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.IsFacilityRequested !== "" && preAuthFormData[0]?.IsFacilityRequested !== undefined  && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <FormControl className={styles.spacingRemoveTop}>
                                    <FormLabel className={styles.labelText} id="demo-row-radio-buttons-group-label">7. {t('PreAuthForm.facilityRequested')}<span className="asteriskSignColor"> *</span></FormLabel>
                                    <RadioGroup
                                        value={preAuthFormData[0].IsFacilityRequested}
                                        row
                                         className='paddingLeftFifteenpx'
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Yes" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                        <FormControlLabel value="false" control={<Radio />} label="No" disabled={true} sx={{ cursor: 'not-allowed' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.FacilityRequestingName && <Grid container sx={{ paddingLeft: '19px' }}>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.nameOfFacility')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    maxLength={200}
                                    minRows={2}
                                    aria-label="requesting custom text"
                                    size="small"
                                    fullWidth
                                    value={preAuthFormData[0].FacilityRequestingName}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.ClinicianName && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t('PreAuthForm.clinicalName')}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    aria-label="clinic text field"
                                    value={preAuthFormData[0].ClinicianName}
                                    fullWidth
                                    minRows={2}
                                    maxLength={200}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AdministratorOrED && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.edAdmin")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    maxLength={200}
                                    aria-label="Admin field"
                                    minRows={2}
                                    value={preAuthFormData[0].AdministratorOrED}
                                    size="small"
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                        {preAuthFormData[0]?.AVPO && <Grid container>
                            <Grid xs={12} className={styles.spacingTop}>
                                <div>
                                    <Typography className={styles.labelText}>
                                        {t("PreAuthForm.avpo")}<span className="asteriskSignColor"> *</span>
                                    </Typography>
                                </div>
                                <TextField
                                    fullWidth
                                    aria-label="avpo field"
                                    minRows={2}
                                    value={preAuthFormData[0].AVPO}
                                    size="small"
                                    maxLength={200}
                                    sx={{ marginTop: '5px', borderRadius:"8px", backgroundColor: 'var(--color-disableField)' ,'& .Mui-disabled': {
                                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.66) !important',
                                      },}}
                                    disabled={true}
                                    inputProps={{ style: { cursor: 'not-allowed' } }}
                                />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "end", padding: "14px 16px"}}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            className='secondaryButton'
                        >
                            {t('NewOrder.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

BariatricSupport.formName = "BariatricSupport";
export default BariatricSupport;