
import React, { Suspense, useEffect } from 'react';import { useDispatch, useSelector } from 'react-redux';import { Routes,Route, Navigate, BrowserRouter as Router} from "react-router-dom";import { LoginInitial, LogoutSuccess } from '../redux/AuthSlice/AuthSlice';
import appSettings from '../configuration/settings.json';
import { useTranslation } from 'react-i18next';
import { LineOfBusiness, LineOfBusinessEnum } from '../constants/BusinessConstants';
import ShareFeedbackThankYouPage from '../pages/support-module/ShareFeedbackThankYouPage/ShareFeedbackThankYouPage';
import PageHistory from '../PageHistory/PageHistory';
import OrdersTab from '../pages/orders-module/OrdersTab/OrdersTab';
import ViewOtherOrders from '../pages/orders-module/ViewOtherOrders/ViewOtherOrders';
import OrderConfirmation from '../pages/orders-module/orders-confirmation/order-confirmation';
import NotificationList from '../pages/NotificationList/NotificationList';
import OrderFeedback from '../pages/support-module/order-feedback/order-feedback';
import SilentTransfer from '../pages/patients-module/SilentTransfer/SilentTransfer';
import Redirect from '../components/Redirect/Redirect';
import MixOrderDetail from '../components/MixOrderDetail/MixOrderDetail';
import EquipmentQuantityUtilizationReport from '../pages/reports-module/equipment-quantity-utilization-report/equipment-quantity-utilization-report';
import PrescriptionReport from  '../pages/reports-module/prescription-report/PrescriptionReport';
import { useAuth0 } from '@auth0/auth0-react';
import { sentryInfo } from '../common/Utils/Sentry/Sentry';

export const ErrorPage = React.lazy(() => import("../components/ErrorPage/ErrorPage"));
const Sidebar = React.lazy(() => import('../components/Sidebar/sidebar'));
export const GetHelp = React.lazy(() => import("../pages/get-help/get-help"));
export const PrivacyPolicy = React.lazy(() => import("../pages/privacy-policy/privacy-policy"));
export const TermsUse = React.lazy(() => import("../pages/terms-use/terms-use"));
export const ContactCustomerSupport = React.lazy(() => import('../pages/contact-customer-support/contact-customer-support'));

export const Login = React.lazy(() => import("../pages/login/login"));
export const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"));

export const Patients = React.lazy(() => import("../pages/patients-module/patients/patients"));
export const AddNewPatients = React.lazy(() => import("../pages/patients-module/add-new-patient/add-new-patient"));
export const PatientsDetails = React.lazy(() => import("../pages/patients-module/patients-details/patients-details"));

export const UserProfile = React.lazy(() => import("../pages/profile-module/user-profile/user-profile"));

export const OrdersComponent = React.lazy(() => import("../pages/orders-module/orders-component/orders-component"))
export const NewOrder = React.lazy(() => import("../pages/orders-module/new-order/new-order"))
export const ConfirmationOrder = React.lazy(() => import("../pages/orders-module/orders-confirmation/order-confirmation"))

export const OrdersApprovalsComponent = React.lazy(() => import("../pages/order-approvals-module/orders-approvals-component/orders-approvals-component"))
export const OrderApprovalTabs = React.lazy(() => import('../pages/order-approvals-module/order-approval-tabs/order-approval-tabs'));

export const RecurringOrders = React.lazy(() => import("../pages/recurring-order-module/recurring-order/recurring-order"))
export const RecurringOrderDetails = React.lazy(() => import("../pages/recurring-order-module/recurring-order-details/recurring-order-details"))
export const EditRecurringOrderSchedule = React.lazy(() => import("../pages/recurring-order-module/edit-recurring-order-schedule/edit-recurring-order-schedule") )

export const BillingDetailReport = React.lazy(() => import("../pages/reports-module/billing-detail-report/billing-detail-report"))

export const AdvanceOrderSearchReport = React.lazy(() => import("../pages/reports-module/advanced-Order-Search-Report/advanceOrderSearchReport"))

export const Support = React.lazy(() => import("../pages/support/support"));
export const SupportComponent = React.lazy(() => import("../pages/support-module/support-component/support-component"))
export const ContactUS = React.lazy(() => import("../pages/support-module/contact-us/contact-us"));
export const ShareYourFeedback = React.lazy(() => import("../pages/support-module/share-feedback/share-feedback"));

export const ItemAddRequestForm = React.lazy(() => import('../pages/support-module/ItemAddRequestForm/ItemAddRequestForm'));
export const ServiceIncidentReport = React.lazy(() => import('../pages/support-module/serviceIncidentReportForm/ServiceIncidentReportForm'));
export const UserAccessRequestForm = React.lazy(() => import('../pages/support-module/UserAccessRequestForm/UserAccessRequestForm'));

export const CostliestItem = React.lazy(() => import("../pages/reports-module/costliest-item/CostliestItem"));
export const DisasterPlanning = React.lazy(() => import("../pages/reports-module/disaster-planning/DisasterPlanning"));
export const checkIfSessionIsInactive = () => {
  const currentTime = new Date();
  const timer = JSON.parse(localStorage.getItem('sessionTimer'));
  return currentTime.getTime() >= timer;
};
const Routing = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(state => state.authState.isLoggedIn);
  const routeDispatch = useDispatch();
  const { logout } = useAuth0()
  let businessName = localStorage.getItem('BusinessName');
  LineOfBusiness.businessName = businessName;

  useEffect(() => {
    checkForImpersonation();

    if (localStorage.getItem('sessionTimer') && checkIfSessionIsInactive()) {
      sessionStorage.setItem('redirectPath', window.location.pathname == "/" ? "/dashboard" : window.location.pathname);
      logout({ logoutParams: { returnTo: window.location.origin } });
      routeDispatch(LogoutSuccess());
    } else if (localStorage.getItem('accessToken') && !isLoggedIn) {
      routeDispatch(LoginInitial());
    }
  }, [isLoggedIn])
  
  const checkForImpersonation = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const impersonationParam = queryParams.get('Impersonation');
    if (impersonationParam) {
      localStorage.setItem('Impersonation', impersonationParam);
      sentryInfo("event-impersonation", "started");
    }
  }

    if(isLoggedIn){
      // 1 for hospice that means patients will show
      switch(businessName){
       case 'Hospice': // 1
        LineOfBusinessEnum.patientsToMembers = t("PatientsListing.patients");
        LineOfBusinessEnum.patientToMember = t("Common.patient");
        LineOfBusinessEnum.hospiceToPace = "Hospice"; // company
        LineOfBusinessEnum.location = "Branch";
        //Hospital at Home in future
       break;
       case 'PACE': // 2
        LineOfBusinessEnum.patientsToMembers = t("Common.members");
        LineOfBusinessEnum.patientToMember = t("Common.member");
        LineOfBusinessEnum.hospiceToPace = "PACE";
        LineOfBusinessEnum.location = "Branch";
        break;
       case 'SNF': // 3
       LineOfBusinessEnum.patientsToMembers = "Residents";
       LineOfBusinessEnum.patientToMember = "Resident";
       LineOfBusinessEnum.hospiceToPace = "Facility"; 
       LineOfBusinessEnum.location = "Branch";
       break;
         
      }
    }

  return (
      <Router>
        <PageHistory />
        <Routes>
          {/* Define your routes here */}
          {!isLoggedIn ? <Route path="/get-help" element={<Suspense><GetHelp /></Suspense>} /> : <Route path="/get-help" element={<Redirect/>} />}
          {!isLoggedIn ? <Route path="/" element={<Suspense><Login /></Suspense>} /> : <Route path="/" element={<Redirect/>} />}
          <Route path="/privacy" element={<Suspense><PrivacyPolicy /></Suspense>} />
          <Route path="/mobile/privacy" element={<Suspense><PrivacyPolicy /></Suspense>} />
          <Route path="/terms" element={<Suspense><TermsUse /></Suspense>} />
          <Route path="/mobile/terms" element={<Suspense><TermsUse /></Suspense>} />
          <Route path="/contact-customer-support" element={<Suspense><ContactCustomerSupport /></Suspense>} />
          <Route path="/mobile/contact-customer-support" element={<Suspense><ContactCustomerSupport /></Suspense>} />
          <Route path={`/how-${appSettings.settings_CompanyAppName}-works`} element={<Suspense><Support /></Suspense>} />
          {<Route element={<Suspense><Sidebar /></Suspense>} >

            <Route path="/dashboard" element={< Suspense > <Dashboard /></Suspense>} />
            <Route path="/dashboard/notifications-list" element={< Suspense > <NotificationList /></Suspense>} />
            
            <Route path="/patients" element={<Suspense><Patients /></Suspense>} />
            <Route path="/members" element={<Suspense><Patients /></Suspense>} />
            <Route path="/residents" element={<Suspense><Patients /></Suspense>} />

            <Route path="/patients/add-new-patient" element={<Suspense><AddNewPatients /></Suspense>} />
            <Route path="/members/add-new-member" element={<Suspense><AddNewPatients /></Suspense>} />
            <Route path="/residents/add-new-resident" element={<Suspense><AddNewPatients /></Suspense>} />
           
            <Route path="/members/edit-member/:patient_id" element={<Suspense><AddNewPatients /></Suspense>} />
            <Route path="/patients/edit-patient/:patient_id"  element={<Suspense><AddNewPatients /></Suspense>} />
            <Route path="/residents/edit-resident/:patient_id" element={<Suspense><AddNewPatients /></Suspense>} />

            <Route path="/patients/patient-details/:patient_id" element={<PatientsDetails />} />
            <Route path="/members/member-details/:patient_id" element={<PatientsDetails />} />
            <Route path="/residents/resident-details/:patient_id" element={<PatientsDetails />} />
          
            <Route path="/user-profile" element={<Suspense><UserProfile /></Suspense>} />

            <Route path="/orders" element={<Suspense><OrdersComponent /></Suspense>} />
            <Route path="/orders/order-confirmation" element={<Suspense><ConfirmationOrder /></Suspense>} />
            <Route path="/orders/order-details/:order_id" element={<Suspense> <OrdersTab /> </Suspense>} />
            <Route path="/orders/new-order" element={<Suspense> <NewOrder /></Suspense>} />
            <Route path="/orders/edit-order/:order_id" element={<Suspense> <NewOrder /></Suspense>} />
            <Route path="/orders/supply-order-details/:order_id" element={<Suspense> <OrdersTab /></Suspense>} />
            <Route path="/orders/mix-order-proof" element={<Suspense><MixOrderDetail /></Suspense>} />

            <Route path="/view-other-orders/:order_id" element={<Suspense> <ViewOtherOrders /></Suspense>} />

            <Route path="/order-approvals" element={<Suspense><OrdersApprovalsComponent /></Suspense>} />
            <Route path="/order-approvals/order-approval-details/:order_id" element={<Suspense><OrderApprovalTabs /></Suspense>} />
            <Route path="/orders/order-confirmation" element={<Suspense><OrderConfirmation /></Suspense>} />
            <Route path="/orders/feedback-order" element={<Suspense><OrderFeedback /></Suspense>} />

            <Route path="/recurring-orders" element={<Suspense><RecurringOrders /></Suspense>} />
            <Route path="/recurring-orders/recurring-order-details/:order_id" element={<Suspense><RecurringOrderDetails /></Suspense>} />
            <Route path="/recurring-orders/edit-recurring-order-schedule/:order_id" element={<Suspense><EditRecurringOrderSchedule /></Suspense>} />
            
            <Route path="/support" element={<Suspense><SupportComponent /></Suspense>} />
            <Route path="/support/item-add-request" element={<Suspense><ItemAddRequestForm /></Suspense>} />
            <Route path="/support/service-incident-report" element={<Suspense><ServiceIncidentReport /></Suspense>} />
            <Route path="/support/user-access-request" element={<Suspense><UserAccessRequestForm /></Suspense>} />
            <Route path="/support/share-your-feedback" element={<Suspense><ShareYourFeedback /></Suspense>} />
            <Route path="/support/contact-us" element={<Suspense><ContactUS /></Suspense>} />
            <Route path="/support/share-feedback-thank-you" element={<Suspense><ShareFeedbackThankYouPage /></Suspense>} />

            <Route path="/reports/prescription" element={<Suspense><PrescriptionReport /></Suspense>} />    
            <Route path="/reports/costliest-item" element={<Suspense><CostliestItem /></Suspense>} />    
            <Route path="/reports/equipment-quantity-utilization" element={<Suspense><EquipmentQuantityUtilizationReport /></Suspense>} />
            <Route path="/reports/billing-detail" element={<Suspense><BillingDetailReport /></Suspense>} />
            <Route path="/reports/disaster-planning" element={<Suspense><DisasterPlanning /></Suspense>} />
            <Route path="/reports/advanced-order-search" element={<Suspense><AdvanceOrderSearchReport /></Suspense>} />

            <Route path="/patients/transfer-request/:id" element={<Suspense><SilentTransfer /></Suspense>} />
            <Route path="/members/transfer-request/:id" element={<Suspense><SilentTransfer /></Suspense>} />
            <Route path="/residents/transfer-request/:id" element={<Suspense><SilentTransfer /></Suspense>} />
          </Route>}
          <Route path="*" element={<Suspense><Navigate to="/error" /></Suspense>} />
          <Route path="/error" element={<Suspense><ErrorPage /></Suspense>} />
          <Route path="/not-found" element={<Suspense><ErrorPage /></Suspense>} />
          <Route path="/forbidden" element={<Suspense><ErrorPage isForbidden={true} /></Suspense>} />
          <Route path="/feedback-expired" element={<Suspense><ErrorPage isFeedbackExpired={true} /></Suspense>} />
          <Route path="/orders/order-feedback" element={<Suspense><OrderFeedback /></Suspense>} />
          <Route path="/support/feedback-thank-you" element={<Suspense><ShareFeedbackThankYouPage /></Suspense>} />
        </Routes>
      </Router>
  );
};


export default Routing;