import React from "react";
import styles from './IDGButton.module.scss';
import Button from '@mui/material/Button';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { HTTPMethod, exportCallApi} from "../../services/HttpService/HttpService";
import { PatientsApi} from "../../constants/ApiConstants";
import { formattedDate } from "../../common/Utils/CommonFunctions/CommonFunctions";

export const downloadCSV = (csvData, filenamePrefix = "") => {
  const csvBlob = new Blob([csvData], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = filenamePrefix ? `${filenamePrefix}_${formattedDate}.csv` : `IDGReport.csv`;
  link.click();
  URL.revokeObjectURL(csvUrl);
};

const IdgButton = ({iDGLoading}) => {
  const handleDownloadIDGReport = async () => {
    iDGLoading(true);
      const requestBody = {
        company_id: 0,
        location_id: 0
      };
      exportCallApi(HTTPMethod.Post, PatientsApi.IDGReport, requestBody).then((response) => response.body)
        .then((body) => {
          const reader = body.getReader();
          return new ReadableStream({
            start(controller) {
              function pushData() {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  pushData();
                });
              }
              pushData();
            },
          });
        })
        .then((stream) =>
          new Response(stream, { headers: { "Content-Type": "text/html" } }).text(),
        )
        .then((result) => {
          iDGLoading(false);
          downloadCSV(result);
        }).catch(error=>console.log(error));
  };


  return (
    <div className={styles.IdgButton} data-testid="IdgButton">
      <Button variant="contained" data-testid='download-btn' className="primaryButton" onClick={handleDownloadIDGReport}>
        <SummarizeIcon className={styles.csvIcon} />
        IDG Report
      </Button>
    </div>
  )
};


export default IdgButton;
