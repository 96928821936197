import React from 'react';
import styles from './ExportReportBtn.module.scss';
import Button from '@mui/material/Button';
import { downloadCSV } from '../../../../components/IDGButton/IDGButton';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportCallApi } from '../../../../services/HttpService/HttpService';

const ExportReportBtn = ({reportUrl, reportMethod, reportRequsetBody = null, filenamePrefix, isMultiCSV=false, handleMultiCSVfile, exportLoading}) => {

  const handleDownloadReport = async () => {
    exportLoading(true);

    if(!isMultiCSV){
      exportCallApi(reportMethod, reportUrl, reportRequsetBody).then((response) => response.body)
        .then((body) => {
          const reader = body.getReader();
          return new ReadableStream({
            start(controller) {
              function pushData() {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  pushData();
                });
              }
              pushData();
            },
          });
        })
        .then((stream) =>
          new Response(stream, { headers: { "Content-Type": "text/html" } }).text(),
        )
        .then((result) => {
          exportLoading(false);
          downloadCSV(result, filenamePrefix);
        })
    }
    else{
      handleMultiCSVfile();
    }
  };


  return (
    <>
      <div className={styles.exportReportBtn} data-testid="exportReportBtn">
        <Button variant="contained" data-testid='downloadBtn'
         className='primaryButton'>
          <FontAwesomeIcon icon={faFileCsv} className={styles.csvIcon} />
          Export
        </Button>
      </div>
    </>
  )
};



export default ExportReportBtn;
