import React, { useEffect, useState } from 'react';
import styles from './SilentTransfer.module.scss';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HTTPMethod, callApi as silentTransferRequest } from '../../../services/HttpService/HttpService';
import { OrdersApi, PatientTransfer } from '../../../constants/ApiConstants';
import Breadcrumb from "../../../components/Breadcrumb/MuiBreadcrumb";
import { LineOfBusinessEnum } from '../../../constants/BusinessConstants';
import { useNavigate} from 'react-router-dom';
import withTitle from '../../../components/withTitle/withTitle';
import { errorToast, successToast } from '../../../components/ToastMessage/ToastMessage';
import PatientDetailsCard from './PatientDetailsCard/PatientDetailsCard';
import EquipmentTransfer from './EquipmentTransfer/EquipmentTransfer';
import BranchAutocomplete from './BranchAutocomplete/BranchAutocomplete';
import CommonNotes from '../../../components/CommonNotes/CommonNotes';
import NoData from '../../../components/noData/noData';
import loader from "../../../../src/assets/loader.gif";
import { useSelector } from "react-redux";
import { deliveryOrderType } from '../../../common/enums';

const SilentTransfer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [noteContent, setNoteContent] = useState("");
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [hospiceError, setHospiceError] = useState(false);
  const [hospiceBranch, setSelectedHospiceBranch] = useState(null);
  const [equipmentData, setEquipmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const patientId = location.pathname.split('/').pop();
  const lineOfBusinessEnum = LineOfBusinessEnum.patientToMember.toLowerCase();
  const PatientTransferDetails = JSON.parse(sessionStorage.getItem("PatientTransferDetails"));
  const userRoles = useSelector(state => state.authState.userData?.data?.user_info?.role);
  
  const handleCreatePickUpOrder = async () => {
    const silentTransferOrderType = (userRoles.is_admin || userRoles.is_hospice_corporate || userRoles.is_hospice_branch) ? "DMEOrder" : "Orders";
    for (const address of equipmentData) {
      if (address.patient_equipment?.length > 0) {
        const providerEquipmentMap = address?.patient_equipment.reduce((pickUpProvider, item) => {
          const providerId = item?.provider[0]?.provider_id;
          if (!pickUpProvider[providerId]) {
            pickUpProvider[providerId] = [];
          }
          pickUpProvider[providerId].push(item);
          return pickUpProvider;
        }, {});
  
        for (const providerId in providerEquipmentMap) {
          const providerEquipments = providerEquipmentMap[providerId];
  
          const itemsToPickup = providerEquipments.flatMap(item => {
            return item?.order_detail_ids?.map(val => ({
              "address_id": address?.address_id,
              "order_detail_id": val,
              "order_id": item?.orders,
              "product_dsc": "",
              "order_type": silentTransferOrderType
            })) 
          });
  
          const requestBody = {
            is_followed: true,
            patient_id: +patientId,
            reason_code: "",
            note: noteContent,
            completed_date: null,
            discharge_date: null,
            items_to_pickup: itemsToPickup,
            requested_date: null,
            reference: "",
            mix_order_configuration: [deliveryOrderType.PickUp],
            asset_tag_data_model: [],
            is_patient_transfer: true
          }
          await silentTransferRequest(HTTPMethod.Post, OrdersApi.pickUpOrder, requestBody);
        }
      }
    }
  }
  const handleCreateDeliveryOrder = async () => {
    for (const address of equipmentData) {
      if (address?.patient_equipment?.length > 0) {
        const providerEquipmentMap = address?.patient_equipment.reduce((deliveryProvider, item) => {
          const providerId = item?.provider[0]?.provider_id;
          if (!deliveryProvider[providerId]) {
            deliveryProvider[providerId] = [];
          }
          deliveryProvider[providerId].push(item);
          return deliveryProvider;
        }, {});

        for (const providerId in providerEquipmentMap) {
          const providerEquipments = providerEquipmentMap[providerId];
          const deliveryEquipment = providerEquipments.map(item => {
            return {
              "product_id": item?.product_id,
              "item_id": 0,
              "product_name": item?.equipment_name,
              "short_description": "", 
              "category": "",
              "cap": item?.equipment_type_tag?.cap,
              "approval": item?.equipment_type_tag?.approval,
              "price": 0, 
              "kit_item": item?.equipment_type_tag?.kit,
              "quantity": item?.quantity,
              "oxygen": false, 
              "disposable": false,
              "medical_necessity": "", 
              "medical_necessity_id": 0,
              "serial_id": 0, 
              "serial_number": item?.serial_number,
              "o2_lot_number": item?.lot_number, 
              "global_product_id": item?.product_id,
              "note": "",
              "external_item_number": "",
              "is_rx": item?.equipment_type_tag?.is_rx
            };
          });
          const providerLocationId = providerEquipments[0]?.provider[0]?.provider_location_id;

          const requestBody = {
            is_followed: PatientTransferDetails?.followed,
            patient_id: +patientId,
            reason_for_delivery: null,
            priority: true,
            is_dme_order: true,
            completed: null,
            cart: deliveryEquipment,
            delivered_equipment_list: [],
            order_priority: 1,
            branch: {
              location_id: hospiceBranch?.location_id,
              company_id: hospiceBranch?.company_id
            },
            warehouse: {
              location_id: providerLocationId,
              company_id: +providerId
            },
            liter_flow_min: null,
            liter_flow_max: null,
            liter_flow_type: null,
            is_pricription_file_uploaded: false,
            pre_authorization_form_data: [],
            address_id: address?.address_id,
            approval_signature: null,
            order_notes: noteContent,
            recurring_order: null,
            requested_date: null,
            reference: null,
            order_id: 0,
            medline_address: null,
            is_for_patient: false,
            is_supplies_order: false,
            supplies_delivery_method: 2,
            mix_order_configuration: [deliveryOrderType.Delivery],
            asset_tag_data_model: [],
            is_patient_transfer: true
          };
          await silentTransferRequest(HTTPMethod.Post, OrdersApi.createOrder, requestBody);
        }
      }
    }
  };
  const handleSilentTransfer = async() => {
    const requestBody = {
        patient_id: +patientId,
        location_id: hospiceBranch?.location_id
    }
    const response = await silentTransferRequest(HTTPMethod.Post, PatientTransfer.transferPatient, requestBody);
    if(response && response?.status_code == 200){
      navigate(`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${patientId}`)
      if(response.data?.model?.is_success){
        successToast(t('SilentTransfer.patientHasBeenTransferredSuccessfully').replace('#patient', LineOfBusinessEnum.patientToMember));
        if (equipmentData?.length > 0) {
          await handleCreatePickUpOrder();
          await handleCreateDeliveryOrder();
        }
        sessionStorage.removeItem("PatientTransferDetails");
      } else {
        errorToast(t('SilentTransfer.theCouldNotBeTransferred').replace('#patient', lineOfBusinessEnum))
      }
    }
  }

  const breadcrumbItems = [
    { label: LineOfBusinessEnum.patientsToMembers, link: `/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}` },
    { label: t('SilentTransfer.transferRequest') }
  ];

  const handleRedirectToPatientDetails = () => {
    const patientId = location.pathname.split('/').pop();
    navigate(`/${LineOfBusinessEnum.patientsToMembers.toLowerCase()}/${LineOfBusinessEnum.patientToMember.toLowerCase()}-details/${patientId}`)
  }

  useEffect(() => {
    if (hospiceBranch) {
      setIsNextButtonEnabled(true);
    } else {
      setIsNextButtonEnabled(false);
    }
  }, [hospiceBranch]);

  return (
    <div className={`${styles.SilentTransfer} mainContainer`} data-testid="SilentTransfer">
      <Breadcrumb items={breadcrumbItems} />
      {isLoading ? (
       <div className={styles.noDataMarginTop}> <NoData message={<img alt="Loading..." src={loader} />} /> </div>
      ) : null }
     <div>
      <PatientDetailsCard patientsDetails={PatientTransferDetails} isLoading={isLoading} />
      <EquipmentTransfer patientId={patientId} equipmentData={equipmentData} setEquipmentData={setEquipmentData} isLoading={isLoading} setIsLoading={setIsLoading} branchId={PatientTransferDetails?.hospice_branch_id} />
      <BranchAutocomplete branchName={PatientTransferDetails?.hospice_branch} hospiceError={hospiceError} setHospiceError={setHospiceError} hospiceBranch={hospiceBranch} setSelectedHospiceBranch={setSelectedHospiceBranch} patientsDetails={PatientTransferDetails} isLoading={isLoading} />
      {!isLoading && <CommonNotes noteContent={noteContent} setNoteContent={setNoteContent} />}

        {!isLoading && <Grid container className={styles.buttonContainer}>
          <Button
            variant="contained" className="secondaryButton" onClick={handleRedirectToPatientDetails}>
            {t('Prescriptions.cancel')}
          </Button>
          <Grid display="flex" alignItems="center">
            <Button variant="contained" disabled={!isNextButtonEnabled} className="primaryButton"
              onClick={handleSilentTransfer}>
              {t('SilentTransfer.transfer')}
            </Button>
          </Grid>
        </Grid>}
     </div> 
    </div>
  )
};

export default withTitle(SilentTransfer, "Transfer Request");